const endpoints = {
  auth: '/auth/local',
  upload: '/upload',

  users: '/users',
  countries: '/countries',
  categories: '/categories',
  suppliers: '/suppliers',
  eeiCategories: '/eei-categories',
  goods: '/goods',
  gpaisData: '/gpais-data',
  orders: '/orders',
  invoices: '/invoices',
  preInvoices: '/pre-invoices',
  creditInvoices: '/credit-invoices',
  configurations: '/configurations',
};

export default endpoints;
