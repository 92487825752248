import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import useStateThrottled from 'web-app-components/hooks/useStateThrottled';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import TextInput from 'web-app-components/components/TextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { DataGrid } from '@material-ui/data-grid';

import goodsClient from '../../../../../../clients/goods';

import makeGoodInternalCode from '../../../../../../utils/makeGoodInternalCode';

const columns = [
  { field: 'title', headerName: 'Pavadinimas', flex: 1, sortable: false },
  {
    field: 'code',
    headerName: 'Vidinis kodas',
    valueGetter: ({ row: { id } }) => makeGoodInternalCode(id),
    flex: 1,
    sortable: false,
  },
];

const useStyles = makeStyles((theme) => ({
  searchBar: {
    marginBottom: theme.spacing(1),
  },
}));

const GoodsSearchDialog = ({ onGoodSelect, onClose, ...restProps }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [throttledSearch, search, setSearch] = useStateThrottled('');

  const [goods, setGoods] = useState();

  useEffect(() => {
    (async () => {
      if (restProps.open) {
        try {
          const query = throttledSearch ? { _q: throttledSearch } : undefined;
          setGoods(await goodsClient.find(query));
        } catch (error) {
          enqueueSnackbar('Nepavyko gauti prekių', { variant: 'error' });
        }
      }
    })();
  }, [restProps.open, throttledSearch, enqueueSnackbar]);

  const handleSearchChange = ({ target: { value } }) => setSearch(value);

  const handleRowClick = ({ row }) => {
    onClose();
    onGoodSelect(row);
  };

  return (
    <Dialog maxWidth="md" fullWidth onClose={onClose} {...restProps}>
      <DialogTitle>Prekės paieška</DialogTitle>
      <DialogContent>
        <TextInput
          className={classes.searchBar}
          label="Paieška"
          value={search}
          onChange={handleSearchChange}
          fullWidth
        />
        <DataGrid
          columns={columns}
          rows={goods || []}
          loading={!goods}
          onRowClick={handleRowClick}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
        />
      </DialogContent>
      <DialogActions>
        <ButtonSlim onClick={onClose}>Uždaryti</ButtonSlim>
      </DialogActions>
    </Dialog>
  );
};

GoodsSearchDialog.propTypes = {
  onGoodSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GoodsSearchDialog;
