const searchOrders = (orders, searchValues) =>
  orders.filter((order) => {
    const buyer = searchValues.buyer.toLowerCase();
    if (buyer) {
      if (
        !(
          order.firstName?.toLowerCase().includes(buyer) ||
          order.lastName?.toLowerCase().includes(buyer) ||
          order.title?.toLowerCase().includes(buyer) ||
          order.code?.toLowerCase().includes(buyer) ||
          order.vatCode?.toLowerCase().includes(buyer) ||
          order.address?.toLowerCase().includes(buyer) ||
          order.payerFirstName?.toLowerCase().includes(buyer) ||
          order.payerLastName?.toLowerCase().includes(buyer) ||
          order.payerTitle?.toLowerCase().includes(buyer) ||
          order.payerCode?.toLowerCase().includes(buyer) ||
          order.payerVatCode?.toLowerCase().includes(buyer) ||
          order.payerAddress?.toLowerCase().includes(buyer)
        )
      ) {
        return false;
      }
    }

    const good = searchValues.good;
    if (good) {
      if (!order.goods.some((orderGood) => orderGood.good?.id === good.id)) {
        return false;
      }
    }

    return true;
  });

export default searchOrders;
