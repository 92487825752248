import INVOICE_TYPES from './INVOICE_TYPES';
import makeInvoiceNumber from './makeInvoiceNumber';

const makeInvoiceTitle = (type, id) =>
  `${
    type === INVOICE_TYPES.credit
      ? 'Kreditinė '
      : type === INVOICE_TYPES.pre
      ? 'Išankstinė '
      : ''
  }PVM sąskaita-faktūra ${
    type === INVOICE_TYPES.credit
      ? 'KRF'
      : type === INVOICE_TYPES.pre
      ? 'IŠF'
      : 'PoliPRO'
  } Nr. ${makeInvoiceNumber(id)}`;

export default makeInvoiceTitle;
