import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import Supplier from './features/Supplier';
import NewSupplier from './features/NewSupplier';
import SuppliersList from './features/SuppliersList';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}/new`}>
        <NewSupplier />
      </Route>
      <Route
        path={`${path}/:id`}
        render={({
          match: {
            params: { id },
          },
        }) => <Supplier id={parseInt(id)} />}
      />
      <Route path={`${path}`}>
        <SuppliersList />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
