import React from 'react';
import PropTypes from 'prop-types';
import calculateGoodTotalPrice from 'goods-common/utils/calculateGoodTotalPrice';
import calculateGoodTotalPiguPrice from 'goods-common/utils/calculateGoodTotalPiguPrice';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import INVOICE_TYPES from '../../../../../../utils/INVOICE_TYPES';
import formatCurrency from '../../../../../../utils/formatCurrency';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  goodRow: {
    overflowWrap: 'anywhere',
  },
}));

const Goods = ({
  invoiceType,
  pigu,
  data,
  discount,
  deliveryPrice,
  additionalReason,
  additionalAmount,
}) => {
  const classes = useStyles();

  const multiplier = invoiceType === INVOICE_TYPES.credit ? -1 : 1;

  const deliveryPriceValue = deliveryPrice * multiplier;

  const additionalAmountValue =
    (invoiceType === INVOICE_TYPES.credit ? additionalAmount || 0 : 0) * -1;

  return (
    <Paper className={classes.root} square elevation={0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nr.</TableCell>
              <TableCell>Prekių, paslaugų pavadinimas</TableCell>
              <TableCell>Mat. vnt.</TableCell>
              <TableCell align="right">Kiekis</TableCell>
              <TableCell align="right">Kaina, EUR</TableCell>
              <TableCell align="right">Suma, EUR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((good, index) => {
              const price = pigu
                ? calculateGoodTotalPiguPrice(good.actualPrice)
                : calculateGoodTotalPrice(good.actualPrice);

              const priceValue = price * multiplier;

              return (
                <TableRow key={good.id} className={classes.goodRow}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{good.good.title}</TableCell>
                  <TableCell>vnt.</TableCell>
                  <TableCell align="right">{good.amount}</TableCell>
                  <TableCell align="right">
                    {formatCurrency(priceValue, true)}
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(good.amount * priceValue, true)}
                  </TableCell>
                </TableRow>
              );
            })}
            {!!discount && (
              <TableRow className={classes.goodRow}>
                <TableCell>{data.length + 1}</TableCell>
                <TableCell>Nuolaida</TableCell>
                <TableCell>vnt.</TableCell>
                <TableCell align="right">1</TableCell>
                <TableCell align="right">
                  -{formatCurrency(discount, true)}
                </TableCell>
                <TableCell align="right">
                  -{formatCurrency(discount, true)}
                </TableCell>
              </TableRow>
            )}
            {!!deliveryPrice && (
              <TableRow className={classes.goodRow}>
                <TableCell>{data.length + (discount ? 1 : 0) + 1}</TableCell>
                <TableCell>Pristatymas</TableCell>
                <TableCell>vnt.</TableCell>
                <TableCell align="right">1</TableCell>
                <TableCell align="right">
                  {formatCurrency(deliveryPriceValue, true)}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(deliveryPriceValue, true)}
                </TableCell>
              </TableRow>
            )}
            {!!(additionalReason && additionalAmount) && (
              <TableRow className={classes.goodRow}>
                <TableCell>
                  {data.length +
                    (discount ? 1 : 0) +
                    (deliveryPrice ? 1 : 0) +
                    1}
                </TableCell>
                <TableCell>{additionalReason}</TableCell>
                <TableCell>vnt.</TableCell>
                <TableCell align="right">1</TableCell>
                <TableCell align="right">
                  {formatCurrency(additionalAmountValue, true)}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(additionalAmountValue, true)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

Goods.propTypes = {
  invoiceType: PropTypes.oneOf(Object.values(INVOICE_TYPES)).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      actualPrice: PropTypes.shape({
        base: PropTypes.number,
        baseCurrencyRate: PropTypes.number,
        delivery: PropTypes.number,
        vat: PropTypes.number,
        profit: PropTypes.number,
        piguCharge: PropTypes.number,
      }).isRequired,
      amount: PropTypes.number,
      good: PropTypes.shape({
        title: PropTypes.string,
      }),
    })
  ).isRequired,
  pigu: PropTypes.bool,
  discount: PropTypes.number,
  deliveryPrice: PropTypes.number,
  additionalReason: PropTypes.string,
  additionalAmount: PropTypes.number,
};

export default Goods;
