import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';

import eeiCategoriesClient from '../../../../clients/eeiCategories';

import { toForm, fromForm } from '../../utils/formData';

import Form from '../../components/Form';

const NewEeiCategory = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      const { id } = await eeiCategoriesClient.create(fromForm(values));
      enqueueSnackbar('EEĮ kategorija išsaugota', { variant: 'success' });
      history.push(`${path.replace('/new', '')}/${id}`);
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti EEĮ kategorijos', {
        variant: 'error',
      });
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Nauja EEI kategorija
      </Typography>
      <Formik initialValues={toForm({})} onSubmit={handleSubmit}>
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default NewEeiCategory;
