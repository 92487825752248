import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from 'web-app-components/components/TextField';
import CheckboxField from 'web-app-components/components/CheckboxField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const CreditInvoiceForm = ({ values }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper>
        <Toolbar>
          <Typography>Grąžinamos prekės (jei grąžinama)</Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableBody>
              {values.orderGoods.map((orderGood, index) => (
                <TableRow key={orderGood.id}>
                  <TableCell>
                    <CheckboxField name={`orderGoods[${index}].selected`} />
                  </TableCell>
                  <TableCell>{orderGood.good.title}</TableCell>
                  <TableCell>
                    <TextField
                      label="Grąžinamas kiekis"
                      type="number"
                      name={`orderGoods[${index}].selectedAmount`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            /{orderGood.amount}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <CheckboxField name="includeDelivery" label="Įtraukti pristatymo kainą" />

      <TextField
        label="Papildoma priežastis"
        name="additionalReason"
        fullWidth
      />

      <TextField
        label="Papildoma suma"
        type="number"
        name="additionalAmount"
        fullWidth
      />
    </div>
  );
};

CreditInvoiceForm.propTypes = {
  values: PropTypes.shape({
    orderGoods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,

        good: PropTypes.shape({
          title: PropTypes.string,
        }),
        amount: PropTypes.number,
      })
    ).isRequired,
  }).isRequired,
};

export default CreditInvoiceForm;
