import {
  formToNumber,
  valueToForm,
  arrayToForm,
} from 'web-app-components/utils/formInputs';

const packagesToForm = (packages) =>
  arrayToForm(packages).map(({ title, weight, ...rest }) => ({
    title: valueToForm(title),
    weight: valueToForm(weight),
    ...rest,
  }));

export const toForm = ({
  eeiCategories,
  soldLocal,
  soldForeign,
  plasticPackages,
  paperPackages,
  packagedGoodCount,
  groupPackageWeight,
  note,
  weight,
  rechargableBatteryWeight,
  rechargableBatteryChemicalComposition,
  batteryWeight,
  batteryChemicalComposition,
}) => ({
  eeiCategories: arrayToForm(eeiCategories).map(({ id }) => id),
  soldLocal: valueToForm(soldLocal),
  soldForeign: valueToForm(soldForeign),
  plasticPackages: packagesToForm(plasticPackages),
  paperPackages: packagesToForm(paperPackages),
  packagedGoodCount: valueToForm(packagedGoodCount),
  groupPackageWeight: valueToForm(groupPackageWeight),
  note: valueToForm(note),
  weight: valueToForm(weight),
  rechargableBatteryWeight: valueToForm(rechargableBatteryWeight),
  rechargableBatteryChemicalComposition: valueToForm(
    rechargableBatteryChemicalComposition
  ),
  batteryWeight: valueToForm(batteryWeight),
  batteryChemicalComposition: valueToForm(batteryChemicalComposition),
});

const formToPackages = (value) =>
  value.map(({ title, weight, ...rest }) => ({
    title,
    weight: formToNumber(weight),
    ...rest,
  }));

export const fromForm = ({
  eeiCategories,
  soldLocal,
  soldForeign,
  plasticPackages,
  paperPackages,
  packagedGoodCount,
  groupPackageWeight,
  note,
  weight,
  rechargableBatteryWeight,
  rechargableBatteryChemicalComposition,
  batteryWeight,
  batteryChemicalComposition,
}) => ({
  eeiCategories,
  soldLocal: formToNumber(soldLocal),
  soldForeign: formToNumber(soldForeign),
  plasticPackages: formToPackages(plasticPackages),
  paperPackages: formToPackages(paperPackages),
  packagedGoodCount: formToNumber(packagedGoodCount),
  groupPackageWeight: formToNumber(groupPackageWeight),
  note,
  weight: formToNumber(weight),
  rechargableBatteryWeight: formToNumber(rechargableBatteryWeight),
  rechargableBatteryChemicalComposition,
  batteryWeight: formToNumber(batteryWeight),
  batteryChemicalComposition,
});
