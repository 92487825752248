import PropTypes from 'prop-types';
import useDialog from 'web-app-components/hooks/useDialog';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import OwnedCountDialog from './components/OwnedCountDialog';

const useStyles = makeStyles((theme) => ({
  ownedCountCell: {
    display: 'flex',
    alignItems: 'center',

    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const GoodRow = ({ data, allowOwnedCountChange, onOwnedCountChange }) => {
  const classes = useStyles();

  const ownedCountDialog = useDialog();

  const handleChangeClick = () => {
    ownedCountDialog.setOpen(true);
  };

  const handleSubmitOwnedCount = async (ownedCount) => {
    await onOwnedCountChange(data, ownedCount);
    ownedCountDialog.setOpen(false);
  };

  return (
    <TableRow>
      <TableCell>{data.title}</TableCell>
      <TableCell className={classes.ownedCountCell}>
        <span>{data.ownedCount}</span>
        {allowOwnedCountChange && (
          <ButtonSlim onClick={handleChangeClick}>Keisti</ButtonSlim>
        )}
      </TableCell>
      <OwnedCountDialog
        initialValue={data.ownedCount}
        onSubmit={handleSubmitOwnedCount}
        {...ownedCountDialog}
      />
    </TableRow>
  );
};

GoodRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    ownedCount: PropTypes.number.isRequired,
  }),
  allowOwnedCountChange: PropTypes.bool,
  onOwnedCountChange: PropTypes.func.isRequired,
};

export default GoodRow;
