import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'web-app-components/components/SelectField';
import MenuItem from '@material-ui/core/MenuItem';

const SupplierSelectField = ({ suppliers, ...props }) => {
  return (
    <SelectField {...props}>
      {suppliers.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
    </SelectField>
  );
};

SupplierSelectField.propTypes = {
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SupplierSelectField;
