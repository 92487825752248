import {
  formToNumber,
  valueToForm,
  arrayToForm,
  boolToForm,
} from 'web-app-components/utils/formInputs';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const toForm = ({
  title,
  color,
  configuration,
  countryOfOrigin,
  categories,
  intrastatCode,
  packageCount,
  packages,
  suppliers,
  price,
  images,
  remoteImages,
  summary,
  description,
  deliveryPrice,
  deliveryPriceLt,
  deliveryPriceLv,
  deliveryPriceEe,
  deliveryTime,
  minOrderAmount,
  warrantyPeriod,
  pigu,
}) => ({
  title: valueToForm(title),
  color: valueToForm(color),
  configuration: valueToForm(configuration),
  countryOfOrigin: countryOfOrigin ? countryOfOrigin.id : '',
  categories: arrayToForm(categories).map(({ id }) => id),
  intrastatCode: valueToForm(intrastatCode),
  packageCount: valueToForm(packageCount),
  packages: arrayToForm(packages).map(
    ({ width, height, depth, weight, ...rest }) => ({
      width: valueToForm(width),
      height: valueToForm(height),
      depth: valueToForm(depth),
      weight: valueToForm(weight),
      ...rest,
    })
  ),
  suppliers: arrayToForm(suppliers).map(({ supplier, goodCode, ...rest }) => ({
    supplier: valueToForm(supplier && supplier.id),
    goodCode: valueToForm(goodCode),
    ...rest,
  })),
  price: {
    ...price,
    base: valueToForm(price && price.base),
    baseCurrencyRate: valueToForm(price && price.baseCurrencyRate),
    delivery: valueToForm(price && price.delivery),
    vat: valueToForm(price && price.vat),
    profit: valueToForm(price && price.profit),
    piguCharge: valueToForm(price && price.piguCharge),
  },
  images: arrayToForm(images).map(({ id, name, ext, formats, url }) => ({
    id,
    name: `${name}${ext}`,
    url: `${SERVER_URL}${formats.thumbnail.url}`,
    originalUrl: `${SERVER_URL}${url}`,
  })),
  remoteImages: arrayToForm(remoteImages).map(({ url }) => ({
    url,
  })),
  summary: valueToForm(summary),
  description: valueToForm(description),
  deliveryPrice: valueToForm(deliveryPrice),
  deliveryPriceLt: valueToForm(deliveryPriceLt),
  deliveryPriceLv: valueToForm(deliveryPriceLv),
  deliveryPriceEe: valueToForm(deliveryPriceEe),
  deliveryTime: valueToForm(deliveryTime),
  minOrderAmount: valueToForm(minOrderAmount),
  warrantyPeriod: valueToForm(warrantyPeriod),
  pigu: boolToForm(pigu),
});

export const fromForm = ({
  title,
  color,
  configuration,
  countryOfOrigin,
  categories,
  intrastatCode,
  packageCount,
  packages,
  suppliers,
  price,
  images,
  summary,
  description,
  deliveryPrice,
  deliveryPriceLt,
  deliveryPriceLv,
  deliveryPriceEe,
  deliveryTime,
  minOrderAmount,
  warrantyPeriod,
  pigu,
}) => ({
  title,
  color,
  configuration,
  countryOfOrigin: formToNumber(countryOfOrigin),
  categories,
  intrastatCode,
  packageCount: formToNumber(packageCount),
  packages: packages.map(({ width, height, depth, weight, ...rest }) => ({
    width: formToNumber(width),
    height: formToNumber(height),
    depth: formToNumber(depth),
    weight: formToNumber(weight),
    ...rest,
  })),
  suppliers: suppliers.map(({ supplier, ...rest }) => ({
    supplier: formToNumber(supplier),
    ...rest,
  })),
  price: {
    ...price,
    base: formToNumber(price.base),
    baseCurrencyRate: formToNumber(price.baseCurrencyRate),
    delivery: formToNumber(price.delivery),
    vat: formToNumber(price.vat),
    profit: formToNumber(price.profit),
    piguCharge: formToNumber(price.piguCharge),
  },
  images: images.map(({ id }) => id),
  summary,
  description,
  deliveryPrice: formToNumber(deliveryPrice),
  deliveryPriceLt: formToNumber(deliveryPriceLt),
  deliveryPriceLv: formToNumber(deliveryPriceLv),
  deliveryPriceEe: formToNumber(deliveryPriceEe),
  deliveryTime: formToNumber(deliveryTime),
  minOrderAmount: formToNumber(minOrderAmount),
  warrantyPeriod: formToNumber(warrantyPeriod),
  pigu,
});
