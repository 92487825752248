import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

import ordersClient from '../../clients/orders';

import GoodTable from './components/GoodTable';

const GoodsToOrder = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [orderGoods, setOrderGoods] = useState();

  const fetchOrderGoods = useCallback(
    async () =>
      _(
        await ordersClient.find({
          archived_eq: false,
          returnRequest_eq: false,
          complaint: false,
        })
      )
        .flatMap((order) =>
          order.goods.map((orderGood) => ({ ...orderGood, _order: order }))
        )
        .filter({ ordered: false })
        .orderBy(['good.id', '_order.created_at'], ['asc', 'desc'])
        .value(),
    []
  );

  const loadOrderGoods = useCallback(async () => {
    try {
      setOrderGoods(await fetchOrderGoods());
    } catch (error) {
      enqueueSnackbar('Nepavyko gauti prekių', { variant: 'error' });
    }
  }, [fetchOrderGoods, enqueueSnackbar]);

  useEffect(() => {
    loadOrderGoods();
  }, [loadOrderGoods]);

  const handleOrdered = async (orderGood) => {
    try {
      const newGoods = [...orderGood._order.goods];
      const index = orderGood._order.goods.findIndex(
        ({ id }) => id === orderGood.id
      );
      newGoods[index] = { ...orderGood, ordered: true };
      await ordersClient.update(orderGood._order.id, { goods: newGoods });

      enqueueSnackbar('Prekė sėkmingai pažymėta kaip užsakyta', {
        variant: 'success',
      });

      loadOrderGoods();
    } catch (error) {
      enqueueSnackbar(
        'Nepavyko pažymėti prekės kaip užsakytos. Pabandykite dar kartą.',
        { variant: 'error' }
      );
    }
  };

  return orderGoods ? (
    <GoodTable data={orderGoods} onOrdered={handleOrdered} />
  ) : (
    <CircularProgress />
  );
};

export default GoodsToOrder;
