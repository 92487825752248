import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

import gpaisDataClient from '../../../../../../clients/gpaisData';

import GpaisData from './components/GpaisData';
import NewGpaisData from './components/NewGpaisData';

const Gpais = ({ goodId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [fetchedData, setFetchedData] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [data, setData] = useState();

  const fetchData = useCallback(async () => {
    const data = await gpaisDataClient.findForGood(goodId);
    setData(data);
    setHasData(!!data);
    setFetchedData(true);
  }, [goodId]);

  useEffect(() => {
    (async () => {
      try {
        await fetchData();
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti GPAIS duomenų', { variant: 'error' });
      }
    })();
  }, [fetchData, enqueueSnackbar]);

  const handleCreate = async () => {
    try {
      await fetchData();
    } catch (error) {
      enqueueSnackbar('Nepavyko gauti GPAIS duomenų', { variant: 'error' });
    }
  };

  return fetchedData ? (
    hasData ? (
      <GpaisData data={data} />
    ) : (
      <NewGpaisData goodId={goodId} onCreate={handleCreate} />
    )
  ) : (
    <CircularProgress />
  );
};

Gpais.propTypes = {
  goodId: PropTypes.number.isRequired,
};

export default Gpais;
