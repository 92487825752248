import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import * as columnWidths from './utils/columnWidths';

import OrderRow from './components/OrderRow';

// NOTE if you only set "width" css property on table cell, it works only while
// there's enough space to fit the cells. when there's not enough space, the
// value will be ignored, as I guess Material-UI does its own magic to calculate
// the widths for the columns dynamically using JS. that's why I set the
// "minWidth" and "maxWidth" css properties instead - it works in every case.
const useStyles = makeStyles((theme) => ({
  buyerCell: {
    minWidth: columnWidths.buyer,
    maxWidth: columnWidths.buyer,
  },
  goodsCell: {
    minWidth: columnWidths.goods,
    maxWidth: columnWidths.goods,
  },
  noteCell: {
    minWidth: columnWidths.note,
    maxWidth: columnWidths.note,
  },
}));

const OrdersTable = ({ orders, onRowClick, onRowUpdate }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell className={classes.buyerCell}>Pirkėjas</TableCell>
            <TableCell className={classes.goodsCell}>Prekės</TableCell>
            <TableCell align="right">Apmokėtas</TableCell>
            <TableCell>Pristatymo vietovė</TableCell>
            <TableCell align="center">Lipdukas</TableCell>
            <TableCell className={classes.noteCell}>Pastabos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <OrderRow
              key={order.id}
              {...order}
              onClick={onRowClick}
              onUpdate={onRowUpdate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  onRowClick: PropTypes.func.isRequired,
  onRowUpdate: PropTypes.func.isRequired,
};

export default OrdersTable;
