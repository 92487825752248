import React from 'react';
import PropTypes from 'prop-types';
import { Form as FormikForm } from 'formik';
import TextField from 'web-app-components/components/TextField';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import makeStyles from '@material-ui/core/styles/makeStyles';

import PackagesField from './components/PackagesField';
import EeiCategoriesSelectField from './components/EeiCategoriesSelectField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  fieldsRow: {
    display: 'flex',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  packagesRow: {
    display: 'flex',

    '& > *': {
      flex: 1,
    },

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const Form = ({ isSubmitting }) => {
  const classes = useStyles();

  return (
    <FormikForm>
      <div className={classes.root}>
        <EeiCategoriesSelectField
          name="eeiCategories"
          label="EEĮ kategorijos"
          fullWidth
        />
        <div className={classes.fieldsRow}>
          <TextField
            type="number"
            name="soldLocal"
            label="Parduotas kiekis LT rinkoje"
            fullWidth
          />
          <TextField
            type="number"
            name="soldForeign"
            label="Parduotas kiekis užsienio rinkoje"
            fullWidth
          />
        </div>
        <div className={classes.packagesRow}>
          <PackagesField name="plasticPackages" label="Plastikinės pakuotės" />
          <PackagesField name="paperPackages" label="Popierinės pakuotės" />
        </div>
        <div className={classes.fieldsRow}>
          <TextField
            type="number"
            name="packagedGoodCount"
            label="Prekinių vienetų kiekis"
            fullWidth
          />
          <TextField
            type="number"
            name="groupPackageWeight"
            label="Grupinės pakuotės svoris, g"
            fullWidth
          />
        </div>
        <TextField name="note" label="Pastabos" multiline fullWidth />
        <TextField
          type="number"
          name="weight"
          label="Prekės svoris (be baterijos ar akumuliatoriaus), t"
          fullWidth
        />
        <div className={classes.fieldsRow}>
          <TextField
            type="number"
            name="rechargableBatteryWeight"
            label="Įmontuoto akumuliatoriaus svoris, t"
            fullWidth
          />
          <TextField
            name="rechargableBatteryChemicalComposition"
            label="Įmontuoto akumuliatoriaus cheminė sudėtis"
            fullWidth
          />
        </div>
        <div className={classes.fieldsRow}>
          <TextField
            type="number"
            name="batteryWeight"
            label="Įmontuotos baterijos svoris, g"
            fullWidth
          />
          <TextField
            name="batteryChemicalComposition"
            label="Įmontuotos baterijos cheminė sudėtis"
            fullWidth
          />
        </div>
        <ButtonPrimary type="submit" disabled={isSubmitting}>
          Išsaugoti
        </ButtonPrimary>
      </div>
    </FormikForm>
  );
};

Form.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default Form;
