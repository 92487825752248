import React from 'react';
import PropTypes from 'prop-types';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import WACFilesField from 'web-app-components/components/FilesField';
import FilePreview from 'web-app-components/components/FilePreview';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DownloadIcon from '@material-ui/icons/GetApp';
import RemoveIcon from '@material-ui/icons/DeleteOutline';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  filesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  file: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minHeight: '200px',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),

    '& > $fileActions': {
      display: 'none',
    },

    '&:hover': {
      '& > $fileActions': {
        display: 'initial',
      },
    },
  },
  filePreview: {
    width: '350px',
    minWidth: '350px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  fileActions: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const FilesField = ({ disabled, ...restProps }) => {
  const classes = useStyles();

  const makeDownloadClickHandler = (file) => () =>
    window.open(file.originalUrl, '_blank');

  return (
    <WACFilesField
      renderValue={({ value, utils: { removeFile } }) => (
        <div className={classes.filesContainer}>
          {value.map((file, index) => (
            <div key={index} className={classes.file}>
              <FilePreview
                className={classes.filePreview}
                file={file}
                disabled={disabled}
              />
              <Paper className={classes.fileActions}>
                {file.id && (
                  <>
                    <ButtonSlim
                      startIcon={<DownloadIcon />}
                      disabled={disabled}
                      onClick={makeDownloadClickHandler(file)}
                    >
                      Atsisiųsti
                    </ButtonSlim>
                  </>
                )}
                <ButtonSlim
                  startIcon={<RemoveIcon />}
                  disabled={disabled}
                  onClick={() => removeFile(index)}
                >
                  Pašalinti
                </ButtonSlim>
              </Paper>
            </div>
          ))}
        </div>
      )}
      {...restProps}
    />
  );
};

FilesField.defaultProps = {
  disabled: false,
};

FilesField.propTypes = {
  disabled: PropTypes.bool,
};

export default FilesField;
