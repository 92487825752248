import { Route, Switch as RRDSwitch } from 'react-router-dom';

import Goods from './features/Goods';

const Switch = () => {
  return (
    <RRDSwitch>
      <Route path="/">
        <Goods />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
