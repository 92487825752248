import uploadClient from '../../../clients/upload';

const prepareImages = async (images) => {
  const newImages = images.filter(({ id }) => !id);

  if (newImages.length === 0) {
    return images;
  }

  const uploadedImages = await uploadClient.upload(newImages);
  const existingImages = images.filter(({ id }) => id);
  return [...existingImages, ...uploadedImages];
};

export default prepareImages;
