import React from 'react';
import PropTypes from 'prop-types';
import { Form as FormikForm } from 'formik';
import TextField from 'web-app-components/components/TextField';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Form = ({ isSubmitting }) => {
  const classes = useStyles();

  return (
    <FormikForm>
      <div className={classes.root}>
        <TextField name="title" label="Pavadinimas" fullWidth />
        <ButtonPrimary type="submit" disabled={isSubmitting}>
          Išsaugoti
        </ButtonPrimary>
      </div>
    </FormikForm>
  );
};

Form.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default Form;
