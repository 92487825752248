import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useField } from 'formik';
import useDialog from 'web-app-components/hooks/useDialog';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import GoodsSearchDialog from './components/GoodsSearchDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const GoodSelectField = ({ className, onSelect, ...restProps }) => {
  const classes = useStyles();

  const [{ value }, , { setValue }] = useField(restProps);

  const goodsSearchDialog = useDialog();

  const handleSelectClick = () => goodsSearchDialog.setOpen(true);

  const handleClearClick = () => setValue(null);

  const handleSelect = (good) => {
    setValue(good);
    onSelect?.(good);
  };

  return (
    <div className={clsx([className, classes.root])}>
      {value && <Typography>{value.title}</Typography>}
      {value && <ButtonSlim onClick={handleClearClick}>Anuliuoti</ButtonSlim>}
      <ButtonSlim onClick={handleSelectClick}>Pasirinkti</ButtonSlim>
      <GoodsSearchDialog {...goodsSearchDialog} onGoodSelect={handleSelect} />
    </div>
  );
};

GoodSelectField.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

export default GoodSelectField;
