import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch as RRDSwitch,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useAxiosReqAuthIntcpt from 'web-app-components/hooks/useAxiosReqAuthIntcpt';
import AppBar from 'web-app-components/components/AppBar';
import AppContent from 'web-app-components/components/AppContent';
import AppDrawer, {
  useAppDrawer,
} from 'web-app-components/components/AppDrawer';
import { useAuthContext } from 'web-app-components/components/AuthProvider';
import ButtonSlim from 'web-app-components/components/ButtonSlim';

import axiosInstance from './utils/axiosInstance';
import endpoints from './utils/endpoints';
import USER_ROLES from './utils/USER_ROLES';

import authClient from './clients/auth';
import usersClient from './clients/users';

import SignInForm from './components/SignInForm';

import WarehouseApp from './features/WarehouseApp';
import OrderGoodsManagerApp from './features/OrderGoodsManagerApp';
import Invoices from './features/Invoices';

import Navigation from './Navigation';
import Switch from './Switch';

const axiosReqAuthIntcptConfig = {
  shouldExcludeUrl: (url) => url === endpoints.auth,
};

const App = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    tokenValid,
    signIn,
    signOut,
    state: { token },
  } = useAuthContext();

  const httpClientCofigured = useAxiosReqAuthIntcpt(
    axiosInstance,
    token,
    axiosReqAuthIntcptConfig
  );

  const [user, setUser] = useState();

  useEffect(() => {
    if (tokenValid && httpClientCofigured) {
      (async () => {
        const user = await usersClient.me();
        setUser(user);
      })();

      return () => {
        setUser();
      };
    }
  }, [tokenValid, httpClientCofigured]);

  const appDrawer = useAppDrawer();

  const handleMenuClick = () => appDrawer.setMobileOpen(!appDrawer.mobileOpen);

  const handleSignInFormSubmit = async ({ username, password }) => {
    try {
      signIn(await authClient.auth(username, password));
    } catch (error) {
      enqueueSnackbar(
        'Nepavyko prisijungti. Įsitikinkite, kad prisijungimo duomenys teisingi ir bandykite dar kartą.',
        { variant: 'error' }
      );
    }
  };

  const handleSignOutClick = () => signOut();

  return user ? (
    <Router>
      {user.role.type === USER_ROLES.warehouseWorker ? (
        <WarehouseApp />
      ) : user.role.type === USER_ROLES.orderGoodsManager ? (
        <OrderGoodsManagerApp />
      ) : (
        <RRDSwitch>
          <Route path="/invoices">
            <Invoices />
          </Route>
          <Route path="/">
            <AppBar
              text="Prekių valdymas"
              actions={
                <ButtonSlim color="inherit" onClick={handleSignOutClick}>
                  Atsijungti
                </ButtonSlim>
              }
              onMenuClick={handleMenuClick}
              enableMenu
            ></AppBar>
            <AppDrawer {...appDrawer}>
              <Navigation />
            </AppDrawer>
            <AppContent>
              <Switch />
            </AppContent>
          </Route>
        </RRDSwitch>
      )}
    </Router>
  ) : (
    <SignInForm onSubmit={handleSignInFormSubmit} />
  );
};

export default App;
