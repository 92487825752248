import {
  formToNumber,
  valueToForm,
  arrayToForm,
  boolToForm,
} from 'web-app-components/utils/formInputs';

const formToPiguVariant = (value) => value || null;

export const toForm = ({
  company,
  firstName,
  lastName,
  title,
  code,
  vatCode,
  address,
  differentPayer,
  payerCompany,
  payerFirstName,
  payerLastName,
  payerTitle,
  payerCode,
  payerVatCode,
  payerAddress,
  pigu,
  piguVariant,
  goods,
  deliveryPrice,
  amountPayed,
  discount,
  zeroVat,
  deliveryLocationNote,
  note,
  deliveryLabel,
  returnRequest,
  returnRequestNote,
  complaint,
  complaintNote,
  creditAccount,
}) => ({
  company: boolToForm(company),
  firstName: valueToForm(firstName),
  lastName: valueToForm(lastName),
  title: valueToForm(title),
  code: valueToForm(code),
  vatCode: valueToForm(vatCode),
  address: valueToForm(address),
  differentPayer: boolToForm(differentPayer),
  payerCompany: boolToForm(payerCompany),
  payerFirstName: valueToForm(payerFirstName),
  payerLastName: valueToForm(payerLastName),
  payerTitle: valueToForm(payerTitle),
  payerCode: valueToForm(payerCode),
  payerVatCode: valueToForm(payerVatCode),
  payerAddress: valueToForm(payerAddress),
  pigu: boolToForm(pigu),
  piguVariant: valueToForm(piguVariant),
  goods: arrayToForm(goods).map(({ amount, ordered, ...rest }) => ({
    amount: valueToForm(amount),
    ordered: boolToForm(ordered),
    ...rest,
  })),
  amountPayed: valueToForm(amountPayed),
  deliveryPrice: valueToForm(deliveryPrice),
  discount: valueToForm(discount),
  zeroVat: boolToForm(zeroVat),
  deliveryLocationNote: valueToForm(deliveryLocationNote),
  note: valueToForm(note),
  deliveryLabel: boolToForm(deliveryLabel),
  returnRequest: boolToForm(returnRequest),
  returnRequestNote: valueToForm(returnRequestNote),
  complaint: boolToForm(complaint),
  complaintNote: valueToForm(complaintNote),
  creditAccount: valueToForm(creditAccount),
});

export const fromForm = ({
  company,
  firstName,
  lastName,
  title,
  code,
  vatCode,
  address,
  differentPayer,
  payerCompany,
  payerFirstName,
  payerLastName,
  payerTitle,
  payerCode,
  payerVatCode,
  payerAddress,
  pigu,
  piguVariant,
  goods,
  deliveryPrice,
  amountPayed,
  discount,
  zeroVat,
  deliveryLocationNote,
  note,
  deliveryLabel,
  returnRequest,
  returnRequestNote,
  complaint,
  complaintNote,
  creditAccount,
}) => ({
  company,
  firstName,
  lastName,
  title,
  code,
  vatCode,
  address,
  differentPayer,
  payerCompany,
  payerFirstName,
  payerLastName,
  payerTitle,
  payerCode,
  payerVatCode,
  payerAddress,
  pigu,
  piguVariant: formToPiguVariant(piguVariant),
  goods: goods.map(({ good, amount, ...rest }) => ({
    good: good && good.id,
    amount: formToNumber(amount),
    ...rest,
  })),
  deliveryPrice: formToNumber(deliveryPrice),
  amountPayed: formToNumber(amountPayed),
  discount: formToNumber(discount),
  zeroVat,
  deliveryLocationNote,
  note,
  deliveryLabel,
  returnRequest,
  returnRequestNote,
  complaint,
  complaintNote,
  creditAccount,
});
