import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';

import gpaisDataClient from '../../../../../../../../clients/gpaisData';

import { fromForm, toForm } from '../../utils/formData';

import Form from '../Form';

const GpaisData = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      const updateData = fromForm(values);
      await gpaisDataClient.update(data.id, updateData);
      enqueueSnackbar('GPAIS duomenys išsaugoti', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti GPAIS duomenų', { variant: 'error' });
    }
  };

  return (
    <Formik initialValues={toForm(data)} onSubmit={handleSubmit}>
      {(props) => <Form {...props} />}
    </Formik>
  );
};

GpaisData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GpaisData;
