import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';

import goodsClient from '../../../../clients/goods';

import { toForm, fromForm } from '../../utils/formData';
import prepareImages from '../../utils/prepareImages';

import Form from '../../components/Form';

const NewGood = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      const images = await prepareImages(values.images);
      const data = fromForm({
        ...values,
        images,
      });
      const { id } = await goodsClient.create(data);

      enqueueSnackbar('Prekė išsaugota', { variant: 'success' });

      history.push(`${path.replace('/new', '')}/${id}`);
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti prekės', { variant: 'error' });
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Nauja prekė
      </Typography>
      <Formik initialValues={toForm({})} onSubmit={handleSubmit}>
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default NewGood;
