import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Formik } from 'formik';
import useDialog from 'web-app-components/hooks/useDialog';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import INVOICE_TYPES from '../../../../utils/INVOICE_TYPES';

import ordersClient from '../../../../clients/orders';
import invoicesClient from '../../../../clients/invoices';
import preInvoicesClient from '../../../../clients/preInvoices';
import creditInvoicesClient from '../../../../clients/creditInvoices';

import { toForm, fromForm } from '../../utils/formData';

import Form from '../../components/Form';

import InvoiceDialog from './components/InvoiceDialog';
import PreInvoiceDialog from './components/PreInvoiceDialog';
import CreditInvoiceDialog from './components/CreditInvoiceDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

const Order = ({ id }) => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState();

  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [loadingPreInvoice, setLoadingPreInvoice] = useState(false);
  const [loadingCreditInvoice, setLoadingCreditInvoice] = useState(false);

  const invoiceDialog = useDialog();
  const preInvoiceDialog = useDialog();
  const creditInvoiceDialog = useDialog();

  useEffect(() => {
    (async () => {
      try {
        setData(await ordersClient.findOne(id));
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti užsakymo', { variant: 'error' });
      }
    })();
  }, [id, enqueueSnackbar]);

  const openInvoice = (invoiceId, type) => {
    window.open(`/invoices/${invoiceId}?type=${type}`, '_blank');
  };

  const handleSubmit = async (values) => {
    try {
      await ordersClient.update(id, fromForm(values));
      enqueueSnackbar('Užsakymas išsaugotas', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti užsakymo', { variant: 'error' });
    }
  };

  const handleInvoiceClick = () => {
    openInvoice(
      typeof data.invoice === 'number' ? data.invoice : data.invoice.id,
      INVOICE_TYPES.regular
    );
  };

  const handlePreInvoiceClick = () => {
    openInvoice(
      typeof data.preInvoice === 'number'
        ? data.preInvoice
        : data.preInvoice.id,
      INVOICE_TYPES.pre
    );
  };

  const handleCreditInvoiceClick = () => {
    creditInvoiceDialog.setOpen(true);
  };

  const handleGenerateInvoiceClick = () => {
    invoiceDialog.setOpen(true);
  };

  const handleGeneratePreInvoiceClick = () => {
    preInvoiceDialog.setOpen(true);
  };

  const handleGenerateCreditInvoiceClick = () => {
    creditInvoiceDialog.setOpen(true);
  };

  const handleInvoiceDialogSubmit = async (changeOwnedCount) => {
    setLoadingInvoice(true);

    try {
      const invoice = await invoicesClient.create({ order: data.id });
      setData((data) => ({ ...data, invoice: invoice.id }));

      enqueueSnackbar('Sąskaita išrašyta sėkmingai', { variant: 'success' });

      if (changeOwnedCount) {
        try {
          await ordersClient.finalize(id);
          enqueueSnackbar('Prekių kiekiai sėkmingai pakeisti', {
            variant: 'success',
          });
          openInvoice(invoice.id, INVOICE_TYPES.regular);
        } catch (error) {
          enqueueSnackbar(
            'Nepavyko pakeisti prekių kiekių. Galite bandyti dar kartą arba atlikti tai rankiniu būdu sandėlio skiltyje.',
            { variant: 'error', autoHideDuration: 10 * 1000 }
          );
          setTimeout(() => {
            openInvoice(invoice.id, INVOICE_TYPES.regular);
          }, 2000);
        }
      } else {
        openInvoice(invoice.id, INVOICE_TYPES.regular);
      }
    } catch (error) {
      if (error.response?.data?.message === 'order_has_invoice') {
        const invoiceId = error.response.data.data.invoiceId;

        setData((data) => ({ ...data, invoice: invoiceId }));

        enqueueSnackbar('Užsakymui sąskaita jau išrašyta', { variant: 'info' });

        setTimeout(() => {
          openInvoice(invoiceId, INVOICE_TYPES.regular);
        }, 2000);
      } else {
        enqueueSnackbar('Nepavyko išrašyti sąskaitos. Pabandykite dar kartą.', {
          variant: 'error',
        });
      }
    }

    setLoadingInvoice(false);
  };

  const handlePreInvoiceDialogSubmit = async () => {
    setLoadingPreInvoice(true);

    try {
      const preInvoice = await preInvoicesClient.create({ order: data.id });
      setData((data) => ({ ...data, preInvoice: preInvoice.id }));

      enqueueSnackbar('Išankstinė sąskaita išrašyta sėkmingai', {
        variant: 'success',
      });

      openInvoice(preInvoice.id, INVOICE_TYPES.pre);
    } catch (error) {
      if (error.response?.data?.message === 'order_has_pre_invoice') {
        const preInvoiceId = error.response.data.data.preInvoiceId;

        setData((data) => ({ ...data, preInvoice: preInvoiceId }));

        enqueueSnackbar('Užsakymui išankstinė sąskaita jau išrašyta', {
          variant: 'info',
        });

        setTimeout(() => {
          openInvoice(preInvoiceId, INVOICE_TYPES.pre);
        }, 2000);
      } else {
        enqueueSnackbar(
          'Nepavyko išrašyti išankstinės sąskaitos. Pabandykite dar kartą.',
          {
            variant: 'error',
          }
        );
      }
    }

    setLoadingPreInvoice(false);
  };

  const handleCreditInvoiceDialogSubmit = async (values) => {
    setLoadingCreditInvoice(true);

    try {
      const creditInvoiceData = {
        order: data.id,

        selectedOrderGoods: values.orderGoods
          .filter(({ selected }) => selected)
          .map(({ id, selectedAmount }) => ({
            id,
            amount: selectedAmount,
          })),

        includeDelivery: values.includeDelivery,

        additionalReason: values.additionalReason,
        additionalAmount: values.additionalAmount || null,
      };

      const creditInvoice = data.creditInvoice
        ? await creditInvoicesClient.update(
            data.creditInvoice.id,
            creditInvoiceData
          )
        : await creditInvoicesClient.create(creditInvoiceData);

      enqueueSnackbar(
        `Kreditinė sąskaita ${
          data.creditInvoice ? 'atnaujinta' : 'išrašyta'
        } sėkmingai`,
        {
          variant: 'success',
        }
      );

      creditInvoiceDialog.setOpen(false);

      openInvoice(creditInvoice.id, INVOICE_TYPES.credit);

      try {
        setData(await ordersClient.findOne(id));
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti atnaujintų užsakymo duomenų. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    } catch (error) {
      if (error.response?.data?.message === 'order_has_credit_invoice') {
        const creditInvoiceId = error.response.data.data.creditInvoiceId;

        enqueueSnackbar('Užsakymui kreditinė sąskaita jau išrašyta', {
          variant: 'info',
        });

        creditInvoiceDialog.setOpen(false);

        try {
          setData(await ordersClient.findOne(id));
        } catch (error) {
          enqueueSnackbar(
            'Nepavyko gauti atnaujintų užsakymo duomenų. Pabandykite perkrauti puslapį.',
            { variant: 'error' }
          );
        }

        setTimeout(() => {
          openInvoice(creditInvoiceId, INVOICE_TYPES.credit);
        }, 2000);
      } else {
        enqueueSnackbar(
          `Nepavyko ${
            data.creditInvoice ? 'atnaujinti' : 'išrašyti'
          } kreditinės sąskaitos. Pabandykite dar kartą.`,
          {
            variant: 'error',
          }
        );
      }
    }

    setLoadingCreditInvoice(false);
  };

  const handleOpenCreditInvoice = () => {
    openInvoice(data.creditInvoice.id, INVOICE_TYPES.credit);
  };

  const handleReturnRequestFulfilledClick = async () => {
    const currentMoment = moment();
    try {
      await ordersClient.update(id, {
        returnRequestFulfilledAt: currentMoment,
        archived: true,
        archivedAt: currentMoment,
      });
      enqueueSnackbar('Grąžinimas įvykdytas. Užsakymas perkeltas į archyvą.', {
        variant: 'success',
      });
      history.push(`${path.replace('/:id', '')}`);
    } catch (error) {
      enqueueSnackbar('Nepavyko įvykdyti grąžinimo', { variant: 'error' });
    }
  };

  const handleComplaintFulfilledClick = async () => {
    const currentMoment = moment();
    try {
      await ordersClient.update(id, {
        complaintFulfilledAt: currentMoment,
        archived: true,
        archivedAt: currentMoment,
      });
      enqueueSnackbar('Skundas įvykdytas. Užsakymas perkeltas į archyvą.', {
        variant: 'success',
      });
      history.push(`${path.replace('/:id', '')}`);
    } catch (error) {
      enqueueSnackbar('Nepavyko įvykdyti skundo', { variant: 'error' });
    }
  };

  const handleArchiveClick = async () => {
    let newArchived = !data.archived;
    try {
      await ordersClient.update(id, {
        archived: newArchived,
        archivedAt: newArchived ? moment() : undefined,
      });
      enqueueSnackbar(
        `Užsakymas ${newArchived ? 'archyvuotas' : 'išarchyvuotas'}`,
        { variant: 'success' }
      );
      history.push(`${path.replace('/:id', '')}`);
    } catch (error) {
      enqueueSnackbar(
        `Nepavyko ${newArchived ? 'archyvuoti' : 'išarchyvuoti'} užsakymo`,
        { variant: 'error' }
      );
    }
  };

  return data ? (
    <div>
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography variant="h5">Užsakymas Nr. {data.id}</Typography>
          <Typography variant="subtitle1">
            Sukurtas: {moment(data.created_at).format('YYYY-MM-DD HH:mm')}
          </Typography>
          {data.archived && (
            <Typography variant="subtitle1">
              Archyvuotas: {moment(data.archivedAt).format('YYYY-MM-DD HH:mm')}
            </Typography>
          )}
        </div>
        {loadingInvoice ? (
          <CircularProgress />
        ) : data.invoice ? (
          <ButtonSlim startIcon={<CheckIcon />} onClick={handleInvoiceClick}>
            Sąskaita
          </ButtonSlim>
        ) : (
          <ButtonSlim onClick={handleGenerateInvoiceClick}>
            Išrašyti SF
          </ButtonSlim>
        )}
        {loadingPreInvoice ? (
          <CircularProgress />
        ) : data.preInvoice ? (
          <ButtonSlim startIcon={<CheckIcon />} onClick={handlePreInvoiceClick}>
            Išankstinė sąskaita
          </ButtonSlim>
        ) : (
          <ButtonSlim onClick={handleGeneratePreInvoiceClick}>
            Išrašyti iš. SF
          </ButtonSlim>
        )}
        {loadingCreditInvoice ? (
          <CircularProgress />
        ) : data.creditInvoice ? (
          <ButtonSlim
            startIcon={<CheckIcon />}
            onClick={handleCreditInvoiceClick}
          >
            Kreditinė sąskaita
          </ButtonSlim>
        ) : (
          <ButtonSlim onClick={handleGenerateCreditInvoiceClick}>
            Išrašyti kred. SF
          </ButtonSlim>
        )}
        {!data.archived && data.returnRequest && (
          <ButtonSlim onClick={handleReturnRequestFulfilledClick}>
            Grąžinimas įvykdytas
          </ButtonSlim>
        )}
        {!data.archived && data.complaint && (
          <ButtonSlim onClick={handleComplaintFulfilledClick}>
            Skundas įvykdytas
          </ButtonSlim>
        )}
        <ButtonSlim onClick={handleArchiveClick}>
          {data.archived ? 'Išarchyvuoti' : 'Archyvuoti'} užsakymą
        </ButtonSlim>
      </div>
      <Formik initialValues={toForm(data)} onSubmit={handleSubmit}>
        {(props) => (
          <Form
            {...props}
            returnRequestFulfilledAt={data.returnRequestFulfilledAt}
            complaintFulfilledAt={data.complaintFulfilledAt}
          />
        )}
      </Formik>
      <InvoiceDialog
        {...invoiceDialog}
        orderId={data.id}
        onSubmit={handleInvoiceDialogSubmit}
      />
      <PreInvoiceDialog
        {...preInvoiceDialog}
        orderId={data.id}
        onSubmit={handlePreInvoiceDialogSubmit}
      />
      <CreditInvoiceDialog
        {...creditInvoiceDialog}
        orderData={data}
        onSubmit={handleCreditInvoiceDialogSubmit}
        onOpenInvoice={handleOpenCreditInvoice}
      />
    </div>
  ) : (
    <CircularProgress />
  );
};

Order.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Order;
