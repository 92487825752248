import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import suppliersClient from '../../../../clients/suppliers';

import { toForm, fromForm } from '../../utils/formData';

import Form from '../../components/Form';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

const Supplier = ({ id }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      try {
        setData(await suppliersClient.findOne(id));
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti tiekėjo', { variant: 'error' });
      }
    })();
  }, [id, enqueueSnackbar]);

  const handleSubmit = async (values) => {
    try {
      await suppliersClient.update(id, fromForm(values));
      enqueueSnackbar('Tiekėjas išsaugotas', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti tiekėjo', { variant: 'error' });
    }
  };

  return data ? (
    <div>
      <div className={classes.header}>
        <Typography className={classes.title} variant="h5">
          Tiekėjas "{data.title}"
        </Typography>
      </div>
      <Formik initialValues={toForm(data)} onSubmit={handleSubmit}>
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  ) : (
    <CircularProgress />
  );
};

Supplier.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Supplier;
