import React from 'react';
import SelectField from 'web-app-components/components/SelectField';
import MenuItem from '@material-ui/core/MenuItem';

import piguVariants from '../../../../utils/piguVariants';

const piguVariantTitles = {
  [piguVariants.lt]: 'LT',
  [piguVariants.lv]: 'LV',
  [piguVariants.ee]: 'EE',
};

const PiguVariantSelectField = (props) => {
  return (
    <SelectField {...props}>
      {Object.keys(piguVariants).map((key) => (
        <MenuItem key={key} value={key}>
          {piguVariantTitles[key]}
        </MenuItem>
      ))}
    </SelectField>
  );
};

export default PiguVariantSelectField;
