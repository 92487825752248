import pcntToCof from 'goods-common/utils/pcntToCof';
import calculateGoodTotalPrice from 'goods-common/utils/calculateGoodTotalPrice';
import calculateGoodTotalPiguPrice from 'goods-common/utils/calculateGoodTotalPiguPrice';

const calculateOrderTotalPrice = (
  goods,
  pigu,
  deliveryPrice,
  discount,
  zeroVat
) => {
  let totalPrice = goods.reduce((totalItemsPrice, { actualPrice, amount }) => {
    if (!actualPrice || !amount) {
      return totalItemsPrice;
    }

    const itemPrice =
      amount *
      (pigu
        ? calculateGoodTotalPiguPrice(actualPrice)
        : calculateGoodTotalPrice(actualPrice));

    return totalItemsPrice + itemPrice;
  }, 0);

  // NOTE at first, the algorithm was to take the largest delivery price of all
  // items as the order delivery price (for this reason each item's delivery
  // prices were saved at order creation), but now the order delivery price is
  // specified by the user in the order form (if nothing changed in the
  // meantime, actualDeliveryPrice* fields can be deleted)
  // const deliveryPrice = goods.reduce(
  //   (
  //     deliveryPrice,
  //     {
  //       actualDeliveryPrice,
  //       actualDeliveryPriceLt,
  //       actualDeliveryPriceLv,
  //       actualDeliveryPriceEe,
  //     }
  //   ) => {
  //     const itemDeliveryPrice =
  //       (pigu
  //         ? piguVariant === piguVariants.lt
  //           ? actualDeliveryPriceLt
  //           : piguVariant === piguVariants.lv
  //           ? actualDeliveryPriceLv
  //           : piguVariant === piguVariants.ee
  //           ? actualDeliveryPriceEe
  //           : 0
  //         : actualDeliveryPrice) || 0;
  //     return itemDeliveryPrice > deliveryPrice
  //       ? itemDeliveryPrice
  //       : deliveryPrice;
  //   },
  //   0
  // );

  // NOTE apply discount, if specified
  if (discount) {
    totalPrice -= discount;
  }

  if (deliveryPrice) {
    totalPrice += deliveryPrice;
  }

  // NOTE VAT should be discounted from the final price, because there are
  // certain rules applied to the price when it's being calculated, such as
  // rounding, so it would yield an incorrect result if we simply recalculated
  // the price calling the calculation function with zero vat - we must
  // "subtract" the VAT from the price that is presented to the buyer.
  if (zeroVat) {
    // NOTE this search of VAT is non-sense, ofcourse... but it's here because
    // the requirements are non-sense... VAT is specified per good, but client
    // requires to "subtract" the VAT from the total price after applying the
    // discount. currently it is taken for granted that every good in the order
    // will have a VAT of 21 or 0. however, I, as a programmer, know that it is
    // possible that a user will specify some other value for some reason, so I
    // just take whatever is given in the first good that has a price specified
    // and don't care any further... when the problem will happen, client will
    // have to decide how to solve this uncertainty...
    const vat =
      goods.find(({ actualPrice }) => actualPrice)?.actualPrice.vat || 21;
    if (vat) {
      totalPrice /= pcntToCof(vat);
    }
  }

  return totalPrice;
};

export default calculateOrderTotalPrice;
