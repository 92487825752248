import { useAuthContext } from 'web-app-components/components/AuthProvider';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import AppBar from 'web-app-components/components/AppBar';
import AppContent from 'web-app-components/components/AppContent';

import Switch from './Switch';

const WarehouseApp = () => {
  const { signOut } = useAuthContext();

  const handleSignOutClick = () => {
    signOut();
  };

  return (
    <>
      <AppBar
        text="Sandėlis"
        actions={
          <ButtonSlim color="inherit" onClick={handleSignOutClick}>
            Atsijungti
          </ButtonSlim>
        }
      ></AppBar>
      <AppContent>
        <Switch />
      </AppContent>
    </>
  );
};

export default WarehouseApp;
