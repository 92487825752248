import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const InvoiceDialog = ({ orderId, onSubmit, onClose, ...restProps }) => {
  const handleCancelClick = () => {
    onClose();
  };

  const handleAcceptClick = () => {
    onSubmit(false);
    onClose();
  };

  const handleAcceptWithCountsClick = () => {
    onSubmit(true);
    onClose();
  };

  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle>Išrašyti sąskaitą-faktūrą?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ar tikrai norite išrašyti sąskaitą-faktūrą užsakymui nr. {orderId}?
          Sąskaitos anuliuoti nebus galima.
        </DialogContentText>
        <DialogContentText>
          Jei norite, kad tuo pačiu būtų automatiškai pakoreguoti prekių kiekiai
          sandėlyje atsižvelgiant į šiam užsakymui skirtus prekių kiekius,
          spauskite "IŠRAŠYTI + KIEKIAI".
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonPrimary onClick={handleCancelClick}>Atšaukti</ButtonPrimary>
        <ButtonPrimary color="secondary" onClick={handleAcceptClick}>
          Išrašyti
        </ButtonPrimary>
        <ButtonPrimary color="secondary" onClick={handleAcceptWithCountsClick}>
          Išrašyti + kiekiai
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

InvoiceDialog.propTypes = {
  orderId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InvoiceDialog;
