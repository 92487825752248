import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useStateThrottled from 'web-app-components/hooks/useStateThrottled';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import TextInput from 'web-app-components/components/TextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ImportedIcon from '@material-ui/icons/Input';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from '@material-ui/data-grid';

import goodsClient from '../../../../clients/goods';

import makeGoodInternalCode from '../../../../utils/makeGoodInternalCode';

import FilterSelectInput, { filters } from './components/FilterSelectInput';

const columns = [
  {
    field: 'fromGoodsTransporter',
    renderHeader: () => <div></div>,
    renderCell: ({ value }) => value && <ImportedIcon />,
    sortable: false,
  },
  { field: 'title', headerName: 'Pavadinimas', flex: 1, sortable: false },
  {
    field: 'code',
    headerName: 'Vidinis kodas',
    valueGetter: ({ row: { id } }) => makeGoodInternalCode(id),
    flex: 1,
    sortable: false,
  },
];

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const GoodsList = () => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const [throttledSearch, search, setSearch] = useStateThrottled('');
  const [filter, setFilter] = useState(filters.ALL);

  const [goods, setGoods] = useState();

  useEffect(() => {
    (async () => {
      try {
        const query =
          filter === filters.ARCHIVED
            ? { archived_eq: true }
            : filter === filters.NO_GPAIS
            ? { archived_eq: false, gpais_data_null: true }
            : { archived_eq: false };

        if (throttledSearch) {
          query._q = throttledSearch;
        }

        setGoods(await goodsClient.find(query));
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti prekių', { variant: 'error' });
      }
    })();
  }, [throttledSearch, filter, enqueueSnackbar]);

  const handleSearchChange = ({ target: { value } }) => setSearch(value);

  const handleFilterChange = ({ target: { value } }) => setFilter(value);

  const handleNewGoodClick = () => history.push(`${path}/new`);

  const handleRowClick = ({ id }) => history.push(`${path}/${id}`);

  return (
    <div>
      <div className={classes.controls}>
        <TextInput
          label="Paieška"
          value={search}
          onChange={handleSearchChange}
        />
        <FilterSelectInput
          label="Rodinys"
          value={filter}
          onChange={handleFilterChange}
        />
        <ButtonSlim startIcon={<AddIcon />} onClick={handleNewGoodClick}>
          Nauja prekė
        </ButtonSlim>
      </div>
      <Paper>
        <DataGrid
          columns={columns}
          rows={goods || []}
          loading={!goods}
          onRowClick={handleRowClick}
          localeText={{}}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
        />
      </Paper>
    </div>
  );
};

export default GoodsList;
