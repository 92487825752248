import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import INVOICE_TYPES from '../../../../utils/INVOICE_TYPES';
import formatCurrency from '../../../../utils/formatCurrency';
import calculateOrderTotalPrice from '../../../../utils/calculateOrderTotalPrice';
import makeInvoiceTitle from '../../../../utils/makeInvoiceTitle';

import footerLogo from './assets/logo-juosta-fakturoms.png';

import totalPriceToWords from './utils/totalPriceToWords';

import Goods from './components/Goods';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(4),

    '@media print': {
      pageBreakAfter: 'always',
    },
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  entityDetailsSection: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing(4),

    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  entityDetails: {
    flex: 1,
  },
  entityDetailTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  goodsSection: {
    marginBottom: theme.spacing(4),
  },
  priceSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(4),
  },
  totalAmount: {
    fontWeight: theme.typography.fontWeightBold,
  },
  totalAmountInWords: {
    fontWeight: theme.typography.fontWeightBold,
  },
  representativesSection: {
    marginBottom: theme.spacing(4),

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  footerLogo: {
    width: '100%',
  },
}));

const InvoiceDocument = ({ type, data, companyDetails }) => {
  const classes = useStyles();

  const {
    id,
    created_at,
    order,
    selectedOrderGoods,
    includeDelivery,
    additionalReason,
    additionalAmount,
  } = data;

  const selectedOrderGoodsMap = _.keyBy(selectedOrderGoods, 'id');

  const goods =
    type === INVOICE_TYPES.credit
      ? order.goods
          .filter((good) => !!selectedOrderGoodsMap[good.id])
          .map((good) => ({
            ...good,
            amount: selectedOrderGoodsMap[good.id].amount,
          }))
      : order.goods;

  const deliveryPrice =
    type === INVOICE_TYPES.credit
      ? includeDelivery
        ? order.deliveryPrice
        : null
      : order.deliveryPrice;

  const discount = type === INVOICE_TYPES.credit ? null : order.discount;

  const additionalAmountValue =
    type === INVOICE_TYPES.credit ? additionalAmount || 0 : 0;

  const totalPriceNoVat =
    calculateOrderTotalPrice(goods, order.pigu, deliveryPrice, discount, true) +
    additionalAmountValue / 1.21;

  const totalPrice =
    calculateOrderTotalPrice(
      goods,
      order.pigu,
      deliveryPrice,
      discount,
      order.zeroVat
    ) + (order.zeroVat ? additionalAmountValue / 1.21 : additionalAmountValue);

  const vat = order.zeroVat ? 0 : totalPrice - totalPriceNoVat;

  const multiplier = type === INVOICE_TYPES.credit ? -1 : 1;
  const totalPriceNoVatValue = totalPriceNoVat * multiplier;
  const totalPriceValue = totalPrice * multiplier;
  const vatValue = vat * multiplier;

  return (
    <Paper className={classes.root} square elevation={0}>
      <div className={classes.titleSection}>
        <Typography variant="h6">{makeInvoiceTitle(type, id)}</Typography>
        <Typography>
          Išrašymo data: {moment(created_at).format('YYYY-MM-DD')}
        </Typography>
      </div>
      <div className={classes.entityDetailsSection}>
        <div className={classes.entityDetails}>
          <Typography>{companyDetails.title}</Typography>
          <Typography>{companyDetails.address}</Typography>
          <Typography>Įmonės kodas: {companyDetails.code}</Typography>
          <Typography>PVM kodas: {companyDetails.vatCode}</Typography>
          <Typography>Tel. nr.: {companyDetails.phoneNumber}</Typography>
          <Typography>{companyDetails.bank.title}</Typography>
          <Typography>Banko kodas: {companyDetails.bank.code}</Typography>
          <Typography>Atsiskaitomoji sąskaita:</Typography>
          <Typography>{companyDetails.bank.account}</Typography>
        </div>
        <div className={classes.entityDetails}>
          <Typography>
            <Typography className={classes.entityDetailTitle} component="span">
              Pirkėjas:
            </Typography>{' '}
            {order.differentPayer
              ? order.payerCompany
                ? order.payerTitle
                : `${order.payerFirstName} ${order.payerLastName}`
              : order.company
              ? order.title
              : `${order.firstName} ${order.lastName}`}
          </Typography>
          <Typography>
            <Typography className={classes.entityDetailTitle} component="span">
              Asmens kodas:
            </Typography>{' '}
            {order.differentPayer
              ? order.payerCompany
                ? null
                : order.payerCode
              : order.company
              ? null
              : order.code}
          </Typography>
          <Typography>
            <Typography className={classes.entityDetailTitle} component="span">
              Įmonės kodas:
            </Typography>{' '}
            {order.differentPayer
              ? order.payerCompany
                ? order.payerCode
                : null
              : order.company
              ? order.code
              : null}
          </Typography>
          <Typography>
            <Typography className={classes.entityDetailTitle} component="span">
              PVM kodas:
            </Typography>{' '}
            {order.differentPayer
              ? order.payerCompany
                ? order.payerVatCode
                : null
              : order.company
              ? order.vatCode
              : null}
          </Typography>
          <Typography>
            <Typography className={classes.entityDetailTitle} component="span">
              Adresas:
            </Typography>{' '}
            {order.differentPayer ? order.payerAddress : order.address}
          </Typography>
        </div>
      </div>
      <div className={classes.goodsSection}>
        <Goods
          invoiceType={type}
          pigu={order.pigu}
          data={goods}
          discount={discount}
          deliveryPrice={deliveryPrice}
          additionalReason={additionalReason}
          additionalAmount={additionalAmount}
        />
      </div>
      <div className={classes.priceSection}>
        <Typography>
          Viso be PVM: {formatCurrency(totalPriceNoVatValue)}
        </Typography>
        <Typography>
          PVM {order.zeroVat ? '0' : '21'}%: {formatCurrency(vatValue)}
        </Typography>
        <Typography className={classes.totalAmount}>
          VISO: {formatCurrency(totalPriceValue)}
        </Typography>
        <Typography className={classes.totalAmountInWords}>
          Suma žodžiais: {totalPriceToWords(totalPriceValue)}
        </Typography>
      </div>
      <div className={classes.representativesSection}>
        <Typography>
          Išrašė{' '}
          <Typography variant="caption">
            (pareigos, vardas, pavardė, parašas)
          </Typography>
          : {companyDetails.representativeOnInvoice.occupation}{' '}
          {companyDetails.representativeOnInvoice.firstName}{' '}
          {companyDetails.representativeOnInvoice.lastName}
        </Typography>
        <Typography>
          Gavo{' '}
          <Typography variant="caption">
            (pareigos, vardas, pavardė, parašas)
          </Typography>
          :
        </Typography>
      </div>
      {/* NOTE temporarily remove logo until client gets new */}
      {/* <div>
        <img className={classes.footerLogo} src={footerLogo} alt="logo" />
      </div> */}
    </Paper>
  );
};

InvoiceDocument.propTypes = {
  type: PropTypes.oneOf(Object.values(INVOICE_TYPES)).isRequired,

  data: PropTypes.shape({
    id: PropTypes.number.isRequired,

    created_at: PropTypes.string.isRequired,

    selectedOrderGoods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
      })
    ),

    includeDelivery: PropTypes.bool,

    additionalReason: PropTypes.string,
    additionalAmount: PropTypes.number,

    order: PropTypes.shape({
      differentPayer: PropTypes.bool,

      company: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      title: PropTypes.string,
      code: PropTypes.string,
      vatCode: PropTypes.string,
      address: PropTypes.string,

      payerCompany: PropTypes.bool,
      payerFirstName: PropTypes.string,
      payerLastName: PropTypes.string,
      payerTitle: PropTypes.string,
      payerCode: PropTypes.string,
      payerVatCode: PropTypes.string,
      payerAddress: PropTypes.string,

      pigu: PropTypes.bool,

      discount: PropTypes.number,

      deliveryPrice: PropTypes.number,

      zeroVat: PropTypes.bool,

      goods: PropTypes.arrayOf(
        PropTypes.shape({
          actualPrice: PropTypes.shape({
            base: PropTypes.number,
            baseCurrencyRate: PropTypes.number,
            delivery: PropTypes.number,
            vat: PropTypes.number,
            profit: PropTypes.number,
            piguCharge: PropTypes.number,
          }).isRequired,
          amount: PropTypes.number,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,

  companyDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    vatCode: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    bank: PropTypes.shape({
      title: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      account: PropTypes.string.isRequired,
    }).isRequired,
    representativeOnInvoice: PropTypes.shape({
      occupation: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default InvoiceDocument;
