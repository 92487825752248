import makeRestClient from 'web-app-components/utils/makeRestClient';

import endpoints from '../../utils/endpoints';
import axiosInstance from '../../utils/axiosInstance';

const client = {
  ...makeRestClient(axiosInstance, endpoints.orders),
  finalize: async (id) => {
    await axiosInstance.post(`${endpoints.orders}/${id}/finalize`);
  },
};

export default client;
