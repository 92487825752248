import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';

import InvoiceRow from './components/InvoiceRow';

const PAGE_SIZE = 10;

const InvoiceTable = ({
  data,
  invoiceCount,
  page,
  onRowClick,
  onPageChange,
}) => {
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableBody>
            {data.map((invoice) => (
              <InvoiceRow
                key={invoice.id}
                data={invoice}
                onClick={onRowClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={invoiceCount}
        page={page}
        rowsPerPage={PAGE_SIZE}
        rowsPerPageOptions={[PAGE_SIZE]}
        onPageChange={onPageChange}
      />
    </Paper>
  );
};

InvoiceTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,

  invoiceCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default InvoiceTable;
