import React from 'react';
import PropTypes from 'prop-types';
import MultiField from 'web-app-components/components/MultiField';

import Good from './components/Good';

const GoodsField = (props) => {
  const renderEntry = (_, index) => (
    <Good index={index} fieldName={props.name} />
  );

  const handleAdd = ({ push }) =>
    push({ good: null, amount: '', ordered: false });

  return (
    <MultiField
      renderEntry={renderEntry}
      addEntryButtonLabel="Pridėti prekę"
      onAdd={handleAdd}
      {...props}
    />
  );
};

GoodsField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default GoodsField;
