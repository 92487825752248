import WACSignInForm from 'web-app-components/components/SignInForm';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
}));

const SignInForm = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WACSignInForm {...props} />
    </div>
  );
};

export default SignInForm;
