import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'web-app-components/components/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  input: {
    flex: 1,
  },
}));

const Package = ({ index, fieldName }) => {
  const classes = useStyles();

  const makeFieldName = (name) => `${fieldName}[${index}].${name}`;

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        type="number"
        name={makeFieldName('width')}
        label="Plotis, cm"
      />
      <TextField
        className={classes.input}
        type="number"
        name={makeFieldName('height')}
        label="Aukštis, cm"
      />
      <TextField
        className={classes.input}
        type="number"
        name={makeFieldName('depth')}
        label="Gylis, cm"
      />
      <TextField
        className={classes.input}
        type="number"
        name={makeFieldName('weight')}
        label="Svoris, kg"
      />
    </div>
  );
};

Package.propTypes = {
  index: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default Package;
