import ListItemLink from 'web-app-components/components/ListItemLink';
import List from '@material-ui/core/List';

const Navigation = () => {
  return (
    <List>
      <ListItemLink to="/goods" text="Prekės" />
      <ListItemLink to="/orders" text="Užsakymai" />
      <ListItemLink to="/goods-to-order" text="Užsakymų lapas" />
      <ListItemLink to="/warehouse" text="Sandėlis" />
      <ListItemLink to="/suppliers" text="Tiekėjai" />
      <ListItemLink to="/categories" text="Kategorijos" />
      <ListItemLink to="/countries" text="Šalys" />
      <ListItemLink to="/eei-categories" text="EEI kategorijos" />
      <ListItemLink to="/billing" text="Finansai" />
    </List>
  );
};

export default Navigation;
