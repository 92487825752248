import { Route, Switch as RRDSwitch } from 'react-router-dom';

import WarehouseSearch from './components/WarehouseSearch';

import Goods from './features/Goods';
import Suppliers from './features/Suppliers';
import Categories from './features/Categories';
import Countries from './features/Countries';
import EeiCategories from './features/EeiCategories';
import Orders from './features/Orders';
import GoodsToOrder from './features/GoodsToOrder';
import Billing from './features/Billing';

const Switch = () => {
  return (
    <RRDSwitch>
      <Route path="/goods">
        <Goods />
      </Route>
      <Route path="/orders">
        <Orders />
      </Route>
      <Route path="/goods-to-order">
        <GoodsToOrder />
      </Route>
      <Route path="/warehouse">
        <WarehouseSearch />
      </Route>
      <Route path="/suppliers">
        <Suppliers />
      </Route>
      <Route path="/categories">
        <Categories />
      </Route>
      <Route path="/countries">
        <Countries />
      </Route>
      <Route path="/eei-categories">
        <EeiCategories />
      </Route>
      <Route path="/billing">
        <Billing />
      </Route>
      <Route path="/">Kuriama...</Route>
    </RRDSwitch>
  );
};

export default Switch;
