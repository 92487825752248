import React from 'react';
import PropTypes from 'prop-types';
import MultiField from 'web-app-components/components/MultiField';

import Package from './components/Package';

const PackagesField = (props) => {
  const renderEntry = (_, index) => (
    <Package index={index} fieldName={props.name} />
  );

  const handleAdd = ({ push }) =>
    push({ width: '', height: '', depth: '', weight: '' });

  return (
    <MultiField
      renderEntry={renderEntry}
      addEntryButtonLabel="Pridėti įpakavimą"
      onAdd={handleAdd}
      {...props}
    />
  );
};

PackagesField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PackagesField;
