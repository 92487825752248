import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'web-app-components/components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';

export const filters = {
  ALL: 'all',
  ARCHIVED: 'archived',
  RETURN_REQUEST: 'return-request',
  COMPLAINT: 'complaint',
};

const filtersArray = Object.values(filters);

const FilterSelectInput = (props) => {
  return (
    <SelectInput {...props}>
      <MenuItem value={filters.ALL}>Aktyvūs</MenuItem>
      <MenuItem value={filters.RETURN_REQUEST}>Grąžinimai</MenuItem>
      <MenuItem value={filters.COMPLAINT}>Skundai</MenuItem>
      <MenuItem value={filters.ARCHIVED}>Archyvas</MenuItem>
    </SelectInput>
  );
};

FilterSelectInput.propTypes = {
  value: PropTypes.oneOf(filtersArray).isRequired,
};

export default FilterSelectInput;
