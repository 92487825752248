import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

import INVOICE_TYPES from '../../../../utils/INVOICE_TYPES';
import makeInvoiceTitle from '../../../../utils/makeInvoiceTitle';

import invoicesClient from '../../../../clients/invoices';
import preInvoicesClient from '../../../../clients/preInvoices';
import creditInvoicesClient from '../../../../clients/creditInvoices';
import configurationsClient from '../../../../clients/configurations';

import InvoiceDocument from '../../components/InvoiceDocument';

const Invoice = ({ id, type }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [invoice, setInvoice] = useState();

  const [companyDetails, setCompanyDetails] = useState();

  useEffect(() => {
    (async () => {
      try {
        setInvoice(
          await (type === INVOICE_TYPES.credit
            ? creditInvoicesClient
            : type === INVOICE_TYPES.pre
            ? preInvoicesClient
            : invoicesClient
          ).findOne(id)
        );
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti sąskaitos. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    })();
  }, [id, type, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      if (invoice) {
        try {
          setCompanyDetails(
            (
              await configurationsClient.find({
                key: 'companyDetails',
                effectiveDate_lte: invoice.created_at,
                _sort: 'effectiveDate:desc',
              })
            )[0].value
          );
        } catch (error) {
          enqueueSnackbar(
            'Nepavyko gauti įmonės informacijos. Pabandykite perkrauti puslapį.',
            { variant: 'error' }
          );
        }
      }
    })();
  }, [invoice, enqueueSnackbar]);

  useEffect(() => {
    // NOTE set title for PDF file (one of print options is "Save to PDF")
    document.title = makeInvoiceTitle(type, id);
  }, [type, id]);

  return companyDetails && invoice ? (
    <InvoiceDocument
      type={type}
      companyDetails={companyDetails}
      data={invoice}
    />
  ) : (
    <CircularProgress />
  );
};

Invoice.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(INVOICE_TYPES)).isRequired,
};

export default Invoice;
