import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const PreInvoiceDialog = ({ orderId, onSubmit, onClose, ...restProps }) => {
  const handleCancelClick = () => {
    onClose();
  };

  const handleAcceptClick = () => {
    onSubmit();
    onClose();
  };

  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle>Išrašyti išankstinę sąskaitą-faktūrą?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ar tikrai norite išrašyti išankstinę sąskaitą-faktūrą užsakymui nr.{' '}
          {orderId}? Išankstinės sąskaitos anuliuoti nebus galima.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonPrimary onClick={handleCancelClick}>Atšaukti</ButtonPrimary>
        <ButtonPrimary color="secondary" onClick={handleAcceptClick}>
          Išrašyti
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

PreInvoiceDialog.propTypes = {
  orderId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PreInvoiceDialog;
