// NOTE moment locale must be setup before anything uses moment, otherwise it
// may cause incosistencies between moments created before the locale is set up,
// i.e. static code like top level constants initialization, and after, i.e.
// react components render methods
import './momentSetup';

import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import 'web-app-components';
import AppBase from 'web-app-components/components/AppBase';
import AuthProvider from 'web-app-components/components/AuthProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import reportWebVitals from './reportWebVitals';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <AppBase>
      <AuthProvider>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </AuthProvider>
    </AppBase>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
