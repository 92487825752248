import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import EeiCategory from './features/EeiCategory';
import NewEeiCategory from './features/NewEeiCategory';
import EeiCategoriesList from './features/EeiCategoriesList';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}/new`}>
        <NewEeiCategory />
      </Route>
      <Route
        path={`${path}/:id`}
        render={({
          match: {
            params: { id },
          },
        }) => <EeiCategory id={parseInt(id)} />}
      />
      <Route path={`${path}`}>
        <EeiCategoriesList />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
