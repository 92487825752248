import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form as FormikForm } from 'formik';
import TextField from 'web-app-components/components/TextField';
import CheckboxField from 'web-app-components/components/CheckboxField';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import formatCurrency from '../../../../utils/formatCurrency';
import calculateOrderTotalPrice from '../../../../utils/calculateOrderTotalPrice';

import PiguVariantSelectField from './components/PiguVariantSelectField';
import GoodsField from './components/GoodsField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  fieldsRow: {
    display: 'flex',
    alignItems: 'center',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  piguCheckbox: {
    whiteSpace: 'nowrap',
  },
  deliveryLabelCheckbox: {
    width: '100%',
  },
  priceColumns: {
    display: 'flex',
  },
  priceInputsColumn: {
    flex: 1,

    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  totalPriceColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  price: {
    fontWeight: 'bold',
  },
  checkboxWithTextWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Form = ({
  isSubmitting,
  values: {
    company,
    differentPayer,
    payerCompany,
    pigu,
    goods,
    deliveryPrice,
    discount,
    zeroVat,
    returnRequest,
    complaint,
  },
  returnRequestFulfilledAt,
  complaintFulfilledAt,
}) => {
  const classes = useStyles();

  const totalPrice = calculateOrderTotalPrice(
    goods,
    pigu,
    deliveryPrice,
    discount,
    zeroVat
  );

  return (
    <FormikForm>
      <div className={classes.root}>
        <div>
          <CheckboxField
            type="checkbox"
            name="company"
            label="Pirkėjas yra įmonė"
          />
        </div>
        {company ? (
          <div className={classes.fieldsRow}>
            <TextField name="title" label="Pavadinimas" fullWidth />
            <TextField name="code" label="Įmonės kodas" fullWidth />
            <TextField name="vatCode" label="PVM mokėtojo kodas" fullWidth />
            <TextField name="address" label="Adresas" fullWidth />
          </div>
        ) : (
          <div className={classes.fieldsRow}>
            <TextField name="firstName" label="Vardas" fullWidth />
            <TextField name="lastName" label="Pavardė" fullWidth />
          </div>
        )}
        <Divider />
        <div>
          <CheckboxField
            type="checkbox"
            name="differentPayer"
            label="Mokės kitas asmuo"
          />
        </div>
        {differentPayer && (
          <>
            <div>
              <CheckboxField
                type="checkbox"
                name="payerCompany"
                label="Mokėtojas yra įmonė"
              />
            </div>
            {payerCompany ? (
              <div className={classes.fieldsRow}>
                <TextField name="payerTitle" label="Pavadinimas" fullWidth />
                <TextField name="payerCode" label="Įmonės kodas" fullWidth />
                <TextField
                  name="payerVatCode"
                  label="PVM mokėtojo kodas"
                  fullWidth
                />
                <TextField name="payerAddress" label="Adresas" fullWidth />
              </div>
            ) : (
              <div className={classes.fieldsRow}>
                <TextField name="payerFirstName" label="Vardas" fullWidth />
                <TextField name="payerLastName" label="Pavardė" fullWidth />
              </div>
            )}
          </>
        )}
        <Divider />
        <div className={classes.fieldsRow}>
          <div className={classes.piguCheckbox}>
            <CheckboxField
              type="checkbox"
              name="pigu"
              label="Užsakymas iš pigu"
            />
          </div>
          {pigu && (
            <PiguVariantSelectField
              name="piguVariant"
              label="pigu variantas"
              fullWidth
            />
          )}
        </div>
        <Divider />
        <GoodsField name="goods" label="Prekės" />
        <Divider />
        <div className={classes.priceColumns}>
          <div className={classes.priceInputsColumn}>
            <TextField
              type="number"
              name="deliveryPrice"
              label="Pristatymo kaina, EUR"
              fullWidth
            />
            <TextField
              type="number"
              name="discount"
              label="Nuolaida, EUR"
              fullWidth
            />
            <CheckboxField type="checkbox" name="zeroVat" label="Nulinis PVM" />
          </div>
          <div className={classes.totalPriceColumn}>
            <Typography>
              Suma:{' '}
              <span className={classes.price}>
                {formatCurrency(totalPrice)}
              </span>
            </Typography>
          </div>
        </div>
        <TextField
          type="number"
          name="amountPayed"
          label="Užsakymas apmokėtas, EUR"
          fullWidth
        />
        <Divider />
        <div className={classes.fieldsRow}>
          <TextField
            name="deliveryLocationNote"
            label="Pristatymo vietovė"
            multiline
            rows={1}
            rowsMax={2}
            fullWidth
          />
          <div className={classes.deliveryLabelCheckbox}>
            <CheckboxField
              type="checkbox"
              name="deliveryLabel"
              label="Paruoštas pristatymo lipdukas"
              color="primary"
            />
          </div>
        </div>
        <TextField
          name="note"
          label="Pastabos"
          multiline
          rows={1}
          rowsMax={2}
          fullWidth
        />
        <Divider />
        {!complaint && (
          <div className={classes.checkboxWithTextWrapper}>
            <CheckboxField
              type="checkbox"
              name="returnRequest"
              label="Grąžinimas"
            />
            {returnRequestFulfilledAt && (
              <Typography component="span">
                (įvykdytas{' '}
                {moment(returnRequestFulfilledAt).format('YYYY-MM-DD HH:mm')})
              </Typography>
            )}
          </div>
        )}
        {returnRequest && (
          <TextField
            name="returnRequestNote"
            label="Grąžinimo pastabos"
            multiline
            rows={1}
            rowsMax={2}
            fullWidth
          />
        )}
        {!returnRequest && (
          <div className={classes.checkboxWithTextWrapper}>
            <CheckboxField type="checkbox" name="complaint" label="Skundas" />
            {complaintFulfilledAt && (
              <Typography component="span">
                (įvykdytas{' '}
                {moment(complaintFulfilledAt).format('YYYY-MM-DD HH:mm')})
              </Typography>
            )}
          </div>
        )}
        {complaint && (
          <TextField
            name="complaintNote"
            label="Skundo pastabos"
            multiline
            rows={1}
            rowsMax={2}
            fullWidth
          />
        )}
        {(returnRequest || complaint) && (
          <TextField
            name="creditAccount"
            label="Kreditinė sąskaita"
            fullWidth
          />
        )}
        <ButtonPrimary type="submit" disabled={isSubmitting}>
          Išsaugoti
        </ButtonPrimary>
      </div>
    </FormikForm>
  );
};

Form.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    company: PropTypes.bool.isRequired,
    differentPayer: PropTypes.bool.isRequired,
    payerCompany: PropTypes.bool.isRequired,
    pigu: PropTypes.bool.isRequired,
    goods: PropTypes.arrayOf(
      PropTypes.shape({
        actualPrice: PropTypes.shape({
          base: PropTypes.number,
          baseCurrencyRate: PropTypes.number,
          delivery: PropTypes.number,
          vat: PropTypes.number,
          profit: PropTypes.number,
          piguCharge: PropTypes.number,
        }),
        actualDeliveryPrice: PropTypes.number,
        actualDeliveryPriceLt: PropTypes.number,
        actualDeliveryPriceLv: PropTypes.number,
        actualDeliveryPriceEe: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number])
          .isRequired,
      })
    ).isRequired,
    deliveryPrice: PropTypes.oneOfType([
      PropTypes.oneOf(['']),
      PropTypes.number,
    ]).isRequired,
    discount: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number])
      .isRequired,
    zeroVat: PropTypes.bool.isRequired,
    returnRequest: PropTypes.bool.isRequired,
    complaint: PropTypes.bool.isRequired,
  }).isRequired,
  returnRequestFulfilledAt: PropTypes.string,
  complaintFulfilledAt: PropTypes.string,
};

export default Form;
