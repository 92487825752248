import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'web-app-components/components/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SupplierSelectField from './components/SupplierSelectField';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  input: {
    flex: 1,
  },
}));

const Supplier = ({ index, fieldName, suppliers }) => {
  const classes = useStyles();

  const makeFieldName = (name) => `${fieldName}[${index}].${name}`;

  return (
    <div className={classes.root}>
      <SupplierSelectField
        className={classes.input}
        name={makeFieldName('supplier')}
        label="Tiekėjas"
        suppliers={suppliers}
      />
      <TextField
        className={classes.input}
        name={makeFieldName('goodCode')}
        label="Prekės kodas (pas tiekėją)"
      />
    </div>
  );
};

Supplier.propTypes = {
  index: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  suppliers: PropTypes.any,
};

export default Supplier;
