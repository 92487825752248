import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useStateThrottled from 'web-app-components/hooks/useStateThrottled';
import TextInput from 'web-app-components/components/TextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import USER_ROLES from '../../utils/USER_ROLES';

import usersClient from '../../clients/users';
import goodsClient from '../../clients/goods';

import GoodsTable from './components/GoodsTable';

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const WarehouseSearch = () => {
  const classes = useStyles();

  const [throttledTitle, title, setTitle] = useStateThrottled('');

  const [user, setUser] = useState();

  const [goods, setGoods] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const searchMode = throttledTitle.length >= 3;

  useEffect(() => {
    (async () => {
      setUser(await usersClient.me());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const query = { _sort: 'title:asc' };

        if (searchMode) {
          query.title_contains = throttledTitle;
          query._limit = 100;
        } else {
          query.ownedCount_null = false;
          query.ownedCount_ne = 0;
        }

        setGoods(await goodsClient.find(query));
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti prekių sąrašo', { variant: 'error' });
      }
    })();
  }, [searchMode, throttledTitle, enqueueSnackbar]);

  const handleTitleChange = ({ target: { value } }) => {
    setTitle(value);
  };

  const handleOwnedCountChange = async (good, ownedCount) => {
    try {
      const updatedGood = await goodsClient.update(good.id, { ownedCount });
      const goodIndex = goods.findIndex((good) => good.id === updatedGood.id);
      const newGoods = [...goods];
      newGoods[goodIndex] = updatedGood;
      setGoods(newGoods);
      enqueueSnackbar('Prekės kiekis sėkmingai atnaujintas', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Nepavyko pakeisti prekės kiekio', { variant: 'error' });
    }
  };

  return (
    <div>
      {user ? (
        <div>
          <div className={classes.controls}>
            <TextInput
              label="Paieška"
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          {goods ? (
            goods.length > 0 ? (
              <div>
                <Typography gutterBottom>
                  {searchMode
                    ? 'Rodomos pirmos 100 paieškos frazęs atitinkančios prekės'
                    : 'Rodomos visos sandėlyje esančios prekės (likutis teigiamas) ir prekės, kurių trūksta (likutis neigiamas)'}
                </Typography>
                <GoodsTable
                  goods={goods}
                  allowOwnedCountChange={
                    user.role.type === USER_ROLES.superAdmin
                  }
                  onOwnedCountChange={handleOwnedCountChange}
                />
              </div>
            ) : (
              <Typography>Prekių nėra</Typography>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default WarehouseSearch;
