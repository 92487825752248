import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../utils/endpoints';

const me = async () => {
  const { data } = await axiosInstance.get(`${endpoints.users}/me`);
  return data;
};

const client = { me };

export default client;
