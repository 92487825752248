import { Route, Switch as RRDSwitch } from 'react-router-dom';

import GoodsToOrder from '../GoodsToOrder';

const Switch = () => {
  return (
    <RRDSwitch>
      <Route path="/">
        <GoodsToOrder />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
