import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';

import gpaisDataClient from '../../../../../../../../clients/gpaisData';

import { fromForm, toForm } from '../../utils/formData';

import Form from '../Form';

const NewGpaisData = ({ goodId, onCreate }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      const data = fromForm(values);
      await gpaisDataClient.create({ ...data, good: goodId });
      enqueueSnackbar('GPAIS duomenys išsaugoti', { variant: 'success' });
      onCreate();
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti GPAIS duomenų', { variant: 'error' });
    }
  };

  return (
    <Formik initialValues={toForm({})} onSubmit={handleSubmit}>
      {(props) => <Form {...props} />}
    </Formik>
  );
};

NewGpaisData.propTypes = {
  goodId: PropTypes.number.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default NewGpaisData;
