import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../utils/endpoints';

const upload = async (files) => {
  const formData = files.reduce((formData, file) => {
    formData.append('files', file);
    return formData;
  }, new FormData());

  const { data } = await axiosInstance.post(endpoints.upload, formData);

  return data;
};

const client = { upload };

export default client;
