import React from 'react';
import PropTypes from 'prop-types';
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import { Editor } from '@tinymce/tinymce-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const init = {
  skin: false,
  content_css: false,
  menubar: false,
  plugins: 'link lists table',
  toolbar:
    'undo redo | styleselect | fontselect | fontsizeselect bold italic underline strikethrough forecolor backcolor | subscript superscript | link unlink | alignleft aligncenter alignright alignjustify | lineheight | numlist bullist indent outdent | table | blockquote | removeformat |',
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: ({ disabled }) =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  },
}));

const RichTextEditor = (props) => {
  const { label } = props;

  const classes = useStyles(props);

  return (
    <div>
      {label && (
        <Typography className={classes.label} gutterBottom>
          {label}
        </Typography>
      )}
      <Editor init={init} {...props} />
    </div>
  );
};

RichTextEditor.defaultProps = {
  disabled: false,
};

RichTextEditor.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RichTextEditor;
