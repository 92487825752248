import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from '@material-ui/data-grid';

import categoriesClient from '../../../../clients/categories';

const columns = [
  { field: 'title', headerName: 'Pavadinimas', flex: 1, sortable: false },
];

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
}));

const CategoriesList = () => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const [categories, setCategories] = useState();

  useEffect(() => {
    (async () => {
      try {
        setCategories(await categoriesClient.find());
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti kategorijų', { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar]);

  const handleNewCategoryClick = () => history.push(`${path}/new`);

  const handleRowClick = ({ id }) => history.push(`${path}/${id}`);

  return (
    <div>
      <div className={classes.controls}>
        <ButtonSlim startIcon={<AddIcon />} onClick={handleNewCategoryClick}>
          Nauja kategorija
        </ButtonSlim>
      </div>
      <Paper>
        <DataGrid
          columns={columns}
          rows={categories || []}
          loading={!categories}
          onRowClick={handleRowClick}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
        />
      </Paper>
    </div>
  );
};

export default CategoriesList;
