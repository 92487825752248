import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useDialog from 'web-app-components/hooks/useDialog';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ordersClient from '../../../../clients/orders';
import goodsClient from '../../../../clients/goods';

import searchOrders from './utils/searchOrders';

import FilterSelectInput, { filters } from './components/FilterSelectInput';
import OrdersTable from './components/OrdersTable';
import SearchDialog from './components/SearchDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),

    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  orderCount: {
    flex: 1,
    fontWeight: 'bold',
  },
}));

const OrdersList = () => {
  const classes = useStyles();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filter = searchParams.has('filter')
    ? searchParams.get('filter')
    : filters.ALL;

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const searchDialog = useDialog();

  const [orders, setOrders] = useState();

  const [searchValues, setSearchValues] = useState();

  const [ordersView, setOrdersView] = useState();

  // NOTE SearchDialog's initial values must include good property which must be
  // a full Good object or null, but we can only store good's id in URL search,
  // so on the first load we must fetch the good specified in URL search
  useEffect(() => {
    (async () => {
      try {
        // NOTE only first load
        if (!searchValues) {
          const searchParams = new URLSearchParams(location.search);
          const searchBuyer = searchParams.has('search_buyer')
            ? searchParams.get('search_buyer')
            : '';
          const searchGoodId = searchParams.has('search_goodId')
            ? searchParams.get('search_goodId')
            : null;

          let searchGood = null;
          if (searchGoodId) {
            searchGood = await goodsClient.findOne(searchGoodId);
          }

          setSearchValues({ buyer: searchBuyer, good: searchGood });
        }
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti ieškomos prekės duomenų', {
          variant: 'error',
        });
      }
    })();
  }, [searchValues, location.search, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      try {
        const query = { _sort: 'created_at:desc' };

        switch (filter) {
          case filters.ARCHIVED: {
            query.archived_eq = true;
            break;
          }
          case filters.RETURN_REQUEST: {
            query.archived_eq = false;
            query.returnRequest_eq = true;
            break;
          }
          case filters.COMPLAINT: {
            query.archived_eq = false;
            query.complaint = true;
            break;
          }
          default: {
            query.archived_eq = false;
            query.returnRequest_eq = false;
            query.complaint = false;
          }
        }

        setOrders(await ordersClient.find(query));
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti užsakymų', { variant: 'error' });
      }
    })();
  }, [filter, enqueueSnackbar]);

  useEffect(() => {
    if (orders && searchValues) {
      setOrdersView(searchOrders(orders, searchValues));
    }
  }, [orders, searchValues]);

  const handleSearchClick = () => searchDialog.setOpen(true);

  const handleSearchSubmit = (values) => {
    if (values.buyer) {
      searchParams.set('search_buyer', values.buyer);
    } else {
      searchParams.delete('search_buyer');
    }
    if (values.good) {
      searchParams.set('search_goodId', values.good.id);
    } else {
      searchParams.delete('search_goodId');
    }
    history.push(`${path}?${searchParams.toString()}`);
    setSearchValues(values);
  };

  const handleFilterChange = ({ target: { value } }) => {
    searchParams.set('filter', value);
    history.push(`${path}?${searchParams.toString()}`);
  };

  const handleNewOrderClick = () => history.push(`${path}/new`);

  const handleRowClick = (id) => history.push(`${path}/${id}`);

  const handleRowUpdate = async (id, update) => {
    try {
      const newOrder = await ordersClient.update(id, update);
      const index = orders.findIndex((order) => order.id === id);
      const newOrders = orders.slice();
      newOrders[index] = newOrder;
      setOrders(newOrders);
    } catch (error) {
      enqueueSnackbar('Nepavyko atnaujinti užsakymo', { variant: 'error' });
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography className={classes.orderCount}>
          {orders && `Užsakymų kiekis: ${orders.length}`}
        </Typography>
        <FilterSelectInput
          label="Rodinys"
          value={filter}
          onChange={handleFilterChange}
        />
        <ButtonSlim startIcon={<SearchIcon />} onClick={handleSearchClick}>
          Ieškoti
        </ButtonSlim>
        <ButtonSlim startIcon={<AddIcon />} onClick={handleNewOrderClick}>
          Naujas užsakymas
        </ButtonSlim>
      </div>
      <div>
        {ordersView ? (
          <OrdersTable
            orders={ordersView}
            onRowClick={handleRowClick}
            onRowUpdate={handleRowUpdate}
          />
        ) : (
          <CircularProgress />
        )}
      </div>
      {searchValues && (
        <SearchDialog
          {...searchDialog}
          initialValues={searchValues}
          onSubmit={handleSearchSubmit}
        />
      )}
    </div>
  );
};

export default OrdersList;
