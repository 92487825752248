import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import { Formik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ImportedIcon from '@material-ui/icons/Input';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import goodsClient from '../../../../clients/goods';

import makeGoodInternalCode from '../../../../utils/makeGoodInternalCode';

import { toForm, fromForm } from '../../utils/formData';
import prepareImages from '../../utils/prepareImages';

import Form from '../../components/Form';

import Gpais from './components/Gpais';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  importedIconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

const Good = ({ id }) => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const [tab, setTab] = useState(0);

  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      try {
        setData(await goodsClient.findOne(id));
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti prekės', { variant: 'error' });
      }
    })();
  }, [id, enqueueSnackbar]);

  const handleTabChange = (_, selectedTab) => setTab(selectedTab);

  const handleArchiveClick = async () => {
    try {
      await goodsClient.update(id, { archived: !data.archived });
      enqueueSnackbar('Prekė archyvuota', { variant: 'success' });
      history.push(`${path.replace('/:id', '')}`);
    } catch (error) {
      enqueueSnackbar('Nepavyko archyvuoti prekės', { variant: 'error' });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const images = await prepareImages(values.images);
      const data = fromForm({
        ...values,
        images,
      });
      await goodsClient.update(id, data);
      enqueueSnackbar('Prekė išsaugota', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti prekės', { variant: 'error' });
    }
  };

  return data ? (
    <div>
      <div className={classes.header}>
        {data.fromGoodsTransporter && (
          <div className={classes.importedIconContainer}>
            <ImportedIcon />
          </div>
        )}
        <div className={classes.title}>
          <Typography variant="h5">
            Prekė "{data.title}" (vidinis kodas {makeGoodInternalCode(data.id)})
          </Typography>
          <Typography variant="subtitle1">
            Atnaujinta: {moment(data.updated_at).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </div>
        <ButtonSlim onClick={handleArchiveClick}>
          {data.archived ? 'Išarchyvuoti' : 'Archyvuoti'} prekę
        </ButtonSlim>
      </div>
      <Paper square>
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={tab}
          onChange={handleTabChange}
        >
          <Tab label="Prekės duomenys" />
          <Tab label="GPAIS" />
        </Tabs>
      </Paper>
      {tab === 0 ? (
        <Formik initialValues={toForm(data)} onSubmit={handleSubmit}>
          {(props) => <Form {...props} />}
        </Formik>
      ) : (
        <Gpais goodId={id} />
      )}
    </div>
  ) : (
    <CircularProgress />
  );
};

Good.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Good;
