import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import GoodRow from './components/GoodRow';

const GoodTable = ({ data, onOrdered }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Prekė</TableCell>
            <TableCell align="right">Kiekis</TableCell>
            <TableCell align="center">Užsakymo data</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((orderGood) => (
            <GoodRow
              key={orderGood.id}
              data={orderGood}
              onOrdered={onOrdered}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

GoodTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  onOrdered: PropTypes.func.isRequired,
};

export default GoodTable;
