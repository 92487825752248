import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import Order from './features/Order';
import NewOrder from './features/NewOrder';
import OrdersList from './features/OrdersList';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}/new`}>
        <NewOrder />
      </Route>
      <Route
        path={`${path}/:id`}
        render={({
          match: {
            params: { id },
          },
        }) => <Order id={parseInt(id)} />}
      />
      <Route path={`${path}`}>
        <OrdersList />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
