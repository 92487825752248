import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CheckboxInput from 'web-app-components/components/CheckboxInput';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import formatCurrency from '../../../../../../../../utils/formatCurrency';

import * as columnWidths from '../../utils/columnWidths';

// NOTE if you only set "width" css property on table cell, it works only while
// there's enough space to fit the cells. when there's not enough space, the
// value will be ignored, as I guess Material-UI does its own magic to calculate
// the widths for the columns dynamically using JS. that's why I set the
// "minWidth" and "maxWidth" css properties instead - it works in every case.
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  buyerCell: {
    minWidth: columnWidths.buyer,
    maxWidth: columnWidths.buyer,
  },
  goodsCell: {
    minWidth: columnWidths.goods,
    maxWidth: columnWidths.goods,
  },
  good: {
    display: 'flex',
    alignItems: 'center',
  },
  goodDescription: {
    flex: 1,
  },
  goodAmount: {
    fontWeight: 'bold',
  },
  orderedCheckbox: {
    marginLeft: theme.spacing(1),
  },
  noteCell: {
    minWidth: columnWidths.note,
    maxWidth: columnWidths.note,
  },
}));

const OrderRow = ({
  id,
  created_at,
  company,
  firstName,
  lastName,
  title,
  code,
  vatCode,
  address,
  goods,
  amountPayed,
  deliveryLocationNote,
  deliveryLabel,
  note,
  onClick,
  onUpdate,
}) => {
  const classes = useStyles();

  const handleClick = () => onClick(id);

  const makeGoodOrderedChangeHandler =
    (goodId) =>
    ({ target: { checked } }) => {
      const index = goods.findIndex((good) => good.id === goodId);
      const newGoods = goods.slice();
      newGoods[index] = { ...newGoods[index], ordered: checked };
      onUpdate(id, { goods: newGoods });
    };

  const handleOrderedCheckboxClick = (event) => event.stopPropagation();

  const handleDeliveryLabelChange = ({ target: { checked } }) =>
    onUpdate(id, { deliveryLabel: checked });

  const handleDeliveryLabelCheckboxClick = (event) => event.stopPropagation();

  return (
    <TableRow className={classes.root} onClick={handleClick}>
      <TableCell>{moment(created_at).format('YYYY-MM-DD')}</TableCell>
      <TableCell className={classes.buyerCell}>
        {company ? (
          <>
            <div>{title}</div>
            {address && <div>{address}</div>}
            {code && <div>Kodas: {code}</div>}
            {vatCode && <div>PVM kodas: {vatCode}</div>}
          </>
        ) : (
          `${firstName} ${lastName}`
        )}
      </TableCell>
      <TableCell className={classes.goodsCell}>
        {goods.map(({ id, good, amount, ordered }) => (
          <div className={classes.good} key={id}>
            <div className={classes.goodDescription}>
              {good ? good.title : '-'}
              <span className={classes.goodAmount}>
                {amount ? ` x ${amount}` : ''}
              </span>
            </div>
            <CheckboxInput
              className={classes.orderedCheckbox}
              label="Užsakyta"
              checked={ordered}
              onChange={makeGoodOrderedChangeHandler(id)}
              onClick={handleOrderedCheckboxClick}
            />
          </div>
        ))}
      </TableCell>
      <TableCell align="right">{formatCurrency(amountPayed)}</TableCell>
      <TableCell>{deliveryLocationNote}</TableCell>
      <TableCell align="center">
        <CheckboxInput
          color="primary"
          checked={deliveryLabel}
          onChange={handleDeliveryLabelChange}
          onClick={handleDeliveryLabelCheckboxClick}
        />
      </TableCell>
      <TableCell className={classes.noteCell}>{note}</TableCell>
    </TableRow>
  );
};

OrderRow.propTypes = {
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  company: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  code: PropTypes.string,
  vatCode: PropTypes.string,
  address: PropTypes.string,
  goods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      good: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
      amount: PropTypes.number,
      ordered: PropTypes.bool,
    })
  ).isRequired,
  amountPayed: PropTypes.number,
  deliveryLocationNote: PropTypes.string,
  deliveryLabel: PropTypes.bool,
  note: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default OrderRow;
