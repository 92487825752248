import _ from 'lodash';
import numberToWords from '@rokasaitenta/number-to-words';

const totalPriceToWords = (totalPrice) => {
  const negative = totalPrice < 0;

  const _totalPrice = negative ? totalPrice * -1 : totalPrice;

  const euros = Math.floor(_totalPrice);
  const eurosWords = euros > 0 && numberToWords(euros);

  const cents = Math.floor((_totalPrice * 100) % 100);
  const centsWords = `${_.padStart(cents, 2, '0')} ct.`;

  const totalPriceWords = `${
    eurosWords
      ? `${_.capitalize(`${negative ? 'minus ' : ''}${eurosWords}`)} EUR `
      : ''
  }${centsWords}`;

  return totalPriceWords;
};

export default totalPriceToWords;
