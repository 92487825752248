import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import TextField from 'web-app-components/components/TextField';
import CheckboxField from 'web-app-components/components/CheckboxField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import GoodSelectField from '../../../../../GoodSelectField';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  goodSelectField: {
    flex: 4,
  },
  amountField: {
    flex: 1,
  },
}));

const Good = ({ index, fieldName }) => {
  const classes = useStyles();

  const makeFieldName = (name) => `${fieldName}[${index}].${name}`;

  const [, , { setValue: setActualPrice }] = useField({
    name: makeFieldName('actualPrice'),
  });
  const [, , { setValue: setActualDeliveryPrice }] = useField({
    name: makeFieldName('actualDeliveryPrice'),
  });
  const [, , { setValue: setActualDeliveryPriceLt }] = useField({
    name: makeFieldName('actualDeliveryPriceLt'),
  });
  const [, , { setValue: setActualDeliveryPriceLv }] = useField({
    name: makeFieldName('actualDeliveryPriceLv'),
  });
  const [, , { setValue: setActualDeliveryPriceEe }] = useField({
    name: makeFieldName('actualDeliveryPriceEe'),
  });

  const handleSelect = ({
    price,
    deliveryPrice,
    deliveryPriceLt,
    deliveryPriceLv,
    deliveryPriceEe,
  }) => {
    setActualPrice({
      ...price,
      // NOTE remove id, because it should be a copy, not a ref to an existing
      // price component
      id: undefined,
    });
    setActualDeliveryPrice(deliveryPrice);
    setActualDeliveryPriceLt(deliveryPriceLt);
    setActualDeliveryPriceLv(deliveryPriceLv);
    setActualDeliveryPriceEe(deliveryPriceEe);
  };

  return (
    <div className={classes.root}>
      <GoodSelectField
        className={classes.goodSelectField}
        name={makeFieldName('good')}
        label="Prekė"
        onSelect={handleSelect}
      />
      <TextField
        className={classes.amountField}
        type="number"
        name={makeFieldName('amount')}
        label="Kiekis"
      />
      <CheckboxField
        type="checkbox"
        name={makeFieldName('ordered')}
        label="Užsakyta"
      />
    </div>
  );
};

Good.propTypes = {
  index: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default Good;
