import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';

import suppliersClient from '../../../../clients/suppliers';

import { toForm, fromForm } from '../../utils/formData';

import Form from '../../components/Form';

const NewSupplier = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    try {
      const { id } = await suppliersClient.create(fromForm(values));
      enqueueSnackbar('Tiekėjas išsaugotas', { variant: 'success' });
      history.push(`${path.replace('/new', '')}/${id}`);
    } catch (error) {
      enqueueSnackbar('Nepavyko išsaugoti tiekėjo', { variant: 'error' });
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Naujas tiekėjas
      </Typography>
      <Formik initialValues={toForm({})} onSubmit={handleSubmit}>
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default NewSupplier;
