import makeRestClient from 'web-app-components/utils/makeRestClient';

import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../utils/endpoints';

const findForGood = async (goodId) => {
  const {
    data: [data],
  } = await axiosInstance.get(endpoints.gpaisData, {
    params: { _limit: 1, good: goodId },
  });
  return data;
};

const client = {
  ...makeRestClient(axiosInstance, endpoints.gpaisData),
  findForGood,
};

export default client;
