import PropTypes from 'prop-types';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const OwnedCountDialog = ({ setOpen, onConfirm, ...restProps }) => {
  const handleConfirmClick = () => {
    setOpen(false);
    onConfirm();
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  return (
    <Dialog {...restProps}>
      <DialogTitle>Pažymėti prekę kaip užsakytą?</DialogTitle>
      <DialogContent>Prekė dings iš sąrašo.</DialogContent>
      <DialogActions>
        <ButtonSlim onClick={handleCancelClick}>Atšaukti</ButtonSlim>
        <ButtonPrimary onClick={handleConfirmClick}>
          Pažymėti kaip užsakytą
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

OwnedCountDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default OwnedCountDialog;
