import { object, array, boolean, number } from 'yup';

const creditInvoiceFormValidationSchema = object({
  orderGoods: array().of(
    object({
      selected: boolean(),
      selectedAmount: number()
        .integer('Grąžinamas kiekis privalo būti sveikas skaičius')
        .positive('Grąžinamas kiekis negali būti mažesnis nei 1')
        .test(
          'is-selected-amount-specified',
          'Grąžinamas kiekis privalo būti nurodytas',
          (value, context) =>
            !context.parent.selected || typeof value === 'number'
        )
        .test(
          'is-selected-amount-below-order-good-amount',
          'Gąžinamas kiekis negali būti didesnis nei užsakytas kiekis',
          (value, context) =>
            typeof value !== 'number' || value <= context.parent.amount
        ),
    })
  ),
});

export default creditInvoiceFormValidationSchema;
