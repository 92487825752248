import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import creditInvoiceFormValidationSchema from './utils/creditInvoiceFormValidationSchema';

import CreditInvoiceForm from './components/CreditInvoiceForm';

const CreditInvoiceDialog = ({
  orderData,

  onSubmit,
  onOpenInvoice,
  onClose,

  ...restProps
}) => {
  const [submitting, setSubmitting] = useState(false);

  const initialFormValues = {
    orderGoods:
      orderData.goods?.map((orderGood) => {
        const selectedOrderGood =
          orderData.creditInvoice?.selectedOrderGoods?.find(
            ({ id }) => id === orderGood.id
          );

        return {
          ...orderGood,
          selected: !!selectedOrderGood,
          selectedAmount: selectedOrderGood?.amount || orderGood.amount || '',
        };
      }) || [],

    includeDelivery: orderData.creditInvoice?.includeDelivery || false,

    additionalReason: orderData.creditInvoice?.additionalReason || '',
    additionalAmount: orderData.creditInvoice?.additionalAmount || '',
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleFormSubmit = async (values) => {
    setSubmitting(true);

    try {
      await onSubmit(values);
    } catch (error) {}

    setSubmitting(false);
  };

  const handleOpenInvoiceClick = () => {
    onOpenInvoice();
  };

  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle>Kreditinė sąskaita-faktūra</DialogTitle>
      <Formik
        initialValues={initialFormValues}
        validationSchema={creditInvoiceFormValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values }) => (
          <Form>
            <DialogContent>
              <DialogContentText>
                {orderData.creditInvoice
                  ? `Kreditinė sąskaita užsakymui nr. ${orderData.id} išrašyta. Galite ją redaguoti.`
                  : `Ar tikrai norite išrašyti kreditinę sąskaitą-faktūrą užsakymui nr. ${orderData.id}? Kreditinės sąskaitos anuliuoti nebus galima.`}
              </DialogContentText>
              <CreditInvoiceForm values={values} />
            </DialogContent>
            <DialogActions>
              <ButtonPrimary onClick={handleCancelClick} disabled={submitting}>
                Atšaukti
              </ButtonPrimary>
              {submitting ? (
                <CircularProgress />
              ) : (
                <ButtonPrimary type="submit" color="secondary">
                  {orderData.creditInvoice
                    ? 'Išsaugoti pakeitimus'
                    : 'Išrašyti'}
                </ButtonPrimary>
              )}
              {orderData.creditInvoice && (
                <ButtonPrimary
                  onClick={handleOpenInvoiceClick}
                  disabled={submitting}
                >
                  Peržiūrėti sąskaitą
                </ButtonPrimary>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

CreditInvoiceDialog.propTypes = {
  orderData: PropTypes.shape({
    id: PropTypes.number.isRequired,

    goods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,

        good: PropTypes.shape({
          title: PropTypes.string,
        }),
        amount: PropTypes.number,
      })
    ),

    creditInvoice: PropTypes.shape({
      selectedOrderGoods: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          amount: PropTypes.number.isRequired,
        })
      ),

      includeDelivery: PropTypes.bool,

      additionalReason: PropTypes.string,
      additionalAmount: PropTypes.number,
    }),
  }).isRequired,

  onSubmit: PropTypes.func.isRequired,
  onOpenInvoice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreditInvoiceDialog;
