import { Route, Switch as RRDSwitch, useRouteMatch } from "react-router-dom";

import InvoicesList from "./features/InvoicesList";

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}`}>
        <InvoicesList />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
