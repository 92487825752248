import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import MultiField from 'web-app-components/components/MultiField';
import CircularProgress from '@material-ui/core/CircularProgress';

import suppliersClient from '../../../../.././../clients/suppliers';

import Supplier from './components/Supplier';

const SuppliersField = (props) => {
  const [suppliers, setSuppliers] = useState();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        setSuppliers(await suppliersClient.find());
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti tiekėjų pasirinkimui', {
          variant: 'error',
        });
      }
    })();
  }, [enqueueSnackbar]);

  const renderEntry = (_, index) => (
    <Supplier index={index} fieldName={props.name} suppliers={suppliers} />
  );

  const handleAdd = ({ push }) => push({ supplier: '', goodCode: '' });

  return suppliers ? (
    <MultiField
      renderEntry={renderEntry}
      addEntryButtonLabel="Pridėti tiekėją"
      onAdd={handleAdd}
      {...props}
    />
  ) : (
    <CircularProgress />
  );
};

SuppliersField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SuppliersField;
