import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import SelectField from 'web-app-components/components/SelectField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import countriesClient from '../../../../../../clients/countries';

const CountrySelectField = (props) => {
  const [countries, setCountries] = useState();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        setCountries(await countriesClient.find());
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti šalių pasirinkimui', {
          variant: 'error',
        });
      }
    })();
  }, [enqueueSnackbar]);

  return countries ? (
    <SelectField {...props}>
      {countries.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
    </SelectField>
  ) : (
    <CircularProgress />
  );
};

export default CountrySelectField;
