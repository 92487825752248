import qs from 'qs';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const axiosInstance = axios.create({
  baseURL: SERVER_URL,
});

axiosInstance.interceptors.request.use((config) => {
  let newConfig = {
    ...config,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  };

  return newConfig;
});

export default axiosInstance;
