import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import TextField from 'web-app-components/components/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import GoodSelectField from '../../../../components/GoodSelectField';

const SearchDialog = ({ initialValues, onClose, onSubmit, ...restProps }) => {
  const handleSubmit = (values) => {
    onClose();
    onSubmit(values);
  };

  const handleClearClick = () => {
    onClose();
    onSubmit({ buyer: '', good: null });
  };

  return (
    <div>
      <Dialog maxWidth="md" fullWidth onClose={onClose} {...restProps}>
        <DialogTitle>Užsakymų paieška</DialogTitle>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <DialogContent>
              <TextField name="buyer" label="Užsakovas" fullWidth />
              <Typography variant="body2">Prekė</Typography>
              <GoodSelectField name="good" label="Prekė" />
            </DialogContent>
            <DialogActions>
              <ButtonSlim onClick={onClose}>Uždaryti</ButtonSlim>
              <ButtonSlim onClick={handleClearClick}>
                Anuliuoti paiešką
              </ButtonSlim>
              <ButtonPrimary type="submit">Ieškoti</ButtonPrimary>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};

SearchDialog.propTypes = {
  initialValues: PropTypes.shape({
    buyer: PropTypes.string,
    good: PropTypes.object,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SearchDialog;
