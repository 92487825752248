import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import TextInput from 'web-app-components/components/TextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';

import INVOICE_TYPES from '../../../../utils/INVOICE_TYPES';

import invoicesClient from '../../../../clients/invoices';
import preInvoicesClient from '../../../../clients/preInvoices';
import creditInvoicesClient from '../../../../clients/creditInvoices';

import FilterSelectInput, { filters } from './components/FilterSelectInput';
import InvoiceTable from './components/InvoiceTable';

const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  buyerControl: {
    flex: 1,
  },
}));

let invoiceNumberTimeoutHandle = null;
let buyerTimeoutHandle = null;

const InvoicesList = () => {
  const classes = useStyles();

  const location = useLocation();

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const searchParams = new URLSearchParams(location.search);

  const fromDate = searchParams.has('fromDate')
    ? searchParams.get('fromDate')
    : null;

  const toDate = searchParams.has('toDate') ? searchParams.get('toDate') : null;

  const page = searchParams.has('page')
    ? parseInt(searchParams.get('page'))
    : 0;

  const filter = searchParams.has('filter')
    ? searchParams.get('filter')
    : filters.REGULAR;

  const invoiceNumber = searchParams.has('invoiceNumber')
    ? searchParams.get('invoiceNumber')
    : '';

  const buyer = searchParams.has('buyer') ? searchParams.get('buyer') : '';

  const [invoiceNumberValue, setInvoiceNumberValue] = useState(invoiceNumber);
  const [buyerValue, setBuyerValue] = useState(buyer);

  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const [invoices, setInvoices] = useState();
  const [invoiceCount, setInvoiceCount] = useState();

  useEffect(() => {
    (async () => {
      setLoadingInvoices(true);

      // NOTE update bulk invoice documents display component query if query is
      // updated here

      try {
        const client =
          filter === filters.CREDIT
            ? creditInvoicesClient
            : filter === filters.PRE
            ? preInvoicesClient
            : invoicesClient;

        const query = { _where: {} };

        if (invoiceNumber) {
          // NOTE using parseInt to make sure zero padding is removed
          query._where.id = parseInt(invoiceNumber, 10);
        } else {
          if (buyer) {
            query._where._or = [
              { 'order.firstName_contains': buyer },
              { 'order.lastName_contains': buyer },
              { 'order.title_contains': buyer },
              { 'order.code_contains': buyer },
              { 'order.vatCode_contains': buyer },
              { 'order.address_contains': buyer },
              { 'order.payerFirstName_contains': buyer },
              { 'order.payerLastName_contains': buyer },
              { 'order.payerTitle_contains': buyer },
              { 'order.payerCode_contains': buyer },
              { 'order.payerVatCode_contains': buyer },
              { 'order.payerAddress_contains': buyer },
            ];
          }

          if (fromDate) {
            query._where.created_at_gte = fromDate;
          }

          if (toDate) {
            query._where.created_at_lte = toDate;
          }

          query._start = page * PAGE_SIZE;
          query._limit = PAGE_SIZE;
          query._sort = 'id:desc';
        }

        const invoices = await client.find(query);
        const invoiceCount = await client.count(query);

        setInvoices(invoices);
        setInvoiceCount(invoiceCount);
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti sąskaitų. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }

      setLoadingInvoices(false);
    })();
  }, [buyer, fromDate, toDate, invoiceNumber, filter, page, enqueueSnackbar]);

  const openInvoice = (invoiceId, type) => {
    window.open(`/invoices/${invoiceId}?type=${type}`, '_blank');
  };

  const handleFromDateChange = (value) => {
    if (value) {
      searchParams.set('fromDate', value.startOf('day').toISOString());
    } else {
      searchParams.delete('fromDate');
    }
    searchParams.delete('page');
    history.push(`${path}?${searchParams.toString()}`);
  };

  const handleToDateChange = (value) => {
    if (value) {
      searchParams.set('toDate', value.endOf('day').toISOString());
    } else {
      searchParams.delete('toDate');
    }
    searchParams.delete('page');
    history.push(`${path}?${searchParams.toString()}`);
  };

  const handleDateRangeFilterClearClick = () => {
    searchParams.delete('fromDate');
    searchParams.delete('toDate');
    searchParams.delete('page');
    history.push(`${path}?${searchParams.toString()}`);
  };

  const handleInvoiceNumberChange = ({ target: { value } }) => {
    setInvoiceNumberValue(value);

    if (invoiceNumberTimeoutHandle !== null) {
      clearTimeout(invoiceNumberTimeoutHandle);
    }

    invoiceNumberTimeoutHandle = setTimeout(() => {
      if (value) {
        searchParams.set('invoiceNumber', value);
      } else {
        searchParams.delete('invoiceNumber');
      }

      searchParams.delete('buyer');
      setBuyerValue('');
      searchParams.delete('fromDate');
      searchParams.delete('toDate');
      searchParams.delete('page');
      history.push(`${path}?${searchParams.toString()}`);

      invoiceNumberTimeoutHandle = null;
    }, 500);
  };

  const handleBuyerChange = ({ target: { value } }) => {
    setBuyerValue(value);

    if (buyerTimeoutHandle !== null) {
      clearTimeout(buyerTimeoutHandle);
    }

    buyerTimeoutHandle = setTimeout(() => {
      if (value) {
        searchParams.set('buyer', value);
      } else {
        searchParams.delete('buyer');
      }
      searchParams.delete('page');
      history.push(`${path}?${searchParams.toString()}`);

      buyerTimeoutHandle = null;
    }, 500);
  };

  const handleFilterChange = ({ target: { value } }) => {
    searchParams.set('filter', value);
    searchParams.delete('page');
    history.push(`${path}?${searchParams.toString()}`);
  };

  const handlePageChange = (_, page) => {
    searchParams.set('page', page);
    history.push(`${path}?${searchParams.toString()}`);
  };

  const handleInvoiceClick = (data) =>
    openInvoice(
      data.id,
      filter === filters.CREDIT
        ? INVOICE_TYPES.credit
        : filter === filters.PRE
        ? INVOICE_TYPES.pre
        : INVOICE_TYPES.regular
    );

  const handleExportClick = () => {
    const search = location.search;
    window.open(
      `/invoices?type=${
        filter === filters.CREDIT
          ? INVOICE_TYPES.credit
          : filter === filters.PRE
          ? INVOICE_TYPES.pre
          : INVOICE_TYPES.regular
      }${
        // NOTE remove "?" from search string
        search ? `&${search.slice(1)}` : ''
      }`,
      '_blank'
    );
  };

  return (
    <div>
      <div className={classes.controls}>
        <TextInput
          label="Sąskaitos Nr."
          value={invoiceNumberValue}
          onChange={handleInvoiceNumberChange}
        />
        <FilterSelectInput
          label="Rodinys"
          value={filter}
          onChange={handleFilterChange}
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.buyerControl}>
          <TextInput
            label="Pirkėjas"
            value={buyerValue}
            onChange={handleBuyerChange}
            disabled={!!invoiceNumber}
          />
        </div>
        <div className={classes.controls}>
          <DatePicker
            inputVariant="outlined"
            label="Nuo"
            value={fromDate}
            onChange={handleFromDateChange}
            disabled={!!invoiceNumber}
            clearable
          />
          <DatePicker
            inputVariant="outlined"
            label="Iki"
            value={toDate}
            onChange={handleToDateChange}
            disabled={!!invoiceNumber}
            clearable
          />
          <ButtonSlim
            onClick={handleDateRangeFilterClearClick}
            disabled={!!invoiceNumber || !(fromDate || toDate)}
          >
            Anuliuoti
          </ButtonSlim>
        </div>
      </div>
      <div className={classes.controls}>
        <ButtonSlim
          onClick={handleExportClick}
          disabled={loadingInvoices || !invoices}
        >
          Eksportuoti
        </ButtonSlim>
      </div>
      {loadingInvoices ? (
        <CircularProgress />
      ) : invoices && typeof invoiceCount === 'number' ? (
        invoiceCount > 0 ? (
          <InvoiceTable
            data={invoices}
            invoiceCount={invoiceCount}
            page={page}
            onRowClick={handleInvoiceClick}
            onPageChange={handlePageChange}
          />
        ) : (
          <Typography>Sąskaitų nėra</Typography>
        )
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default InvoicesList;
