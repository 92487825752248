import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import INVOICE_TYPES from '../../utils/INVOICE_TYPES';

import Invoice from './features/Invoice';
import InvoicesList from './features/InvoicesList';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}/:id`}>
        {({ match, location }) => {
          const searchParams = new URLSearchParams(location.search);

          const type = searchParams.has('type')
            ? searchParams.get('type')
            : INVOICE_TYPES.regular;

          const id = parseInt(match.params.id, 10);

          return <Invoice id={id} type={type} />;
        }}
      </Route>
      <Route path={`${path}`}>
        {({ location }) => {
          const searchParams = new URLSearchParams(location.search);

          const type = searchParams.has('type')
            ? searchParams.get('type')
            : INVOICE_TYPES.regular;

          const buyer = searchParams.has('buyer')
            ? searchParams.get('buyer')
            : null;

          const fromDate = searchParams.has('fromDate')
            ? searchParams.get('fromDate')
            : null;

          const toDate = searchParams.has('toDate')
            ? searchParams.get('toDate')
            : null;

          return (
            <InvoicesList
              type={type}
              buyer={buyer}
              fromDate={fromDate}
              toDate={toDate}
            />
          );
        }}
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
