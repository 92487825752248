import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../utils/endpoints';

const auth = async (username, password) => {
  const {
    data: { jwt },
  } = await axiosInstance.post(endpoints.auth, {
    identifier: username,
    password,
  });
  return jwt;
};

const client = { auth };

export default client;
