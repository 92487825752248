import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import TextField from 'web-app-components/components/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import validationSchema from './utils/validationSchema';

const OwnedCountDialog = ({
  initialValue,
  setOpen,
  onSubmit,
  ...restProps
}) => {
  const handleSubmit = async ({ ownedCount }) => {
    await onSubmit(ownedCount);
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  return (
    <Dialog {...restProps}>
      <DialogTitle>Test</DialogTitle>
      <Formik
        initialValues={{ ownedCount: initialValue || '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <TextField
                type="number"
                name="ownedCount"
                label="Naujas kiekis"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <ButtonPrimary type="submit" disabled={isSubmitting}>
                Išsaugoti
              </ButtonPrimary>
              <ButtonSlim onClick={handleCancelClick} disabled={isSubmitting}>
                Atšaukti
              </ButtonSlim>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

OwnedCountDialog.propTypes = {
  initialValue: PropTypes.number,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OwnedCountDialog;
