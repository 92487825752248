import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

import INVOICE_TYPES from '../../../../utils/INVOICE_TYPES';

import invoicesClient from '../../../../clients/invoices';
import preInvoicesClient from '../../../../clients/preInvoices';
import creditInvoicesClient from '../../../../clients/creditInvoices';
import configurationsClient from '../../../../clients/configurations';

import InvoiceDocument from '../../components/InvoiceDocument';

const InvoicesList = ({ type, buyer, fromDate, toDate }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [companyDetailsList, setCompanyDetailsList] = useState();
  const [invoices, setInvoices] = useState();

  useEffect(() => {
    (async () => {
      try {
        const client =
          type === INVOICE_TYPES.credit
            ? creditInvoicesClient
            : type === INVOICE_TYPES.pre
            ? preInvoicesClient
            : invoicesClient;

        const query = { _where: {} };

        if (buyer) {
          query._where._or = [
            { 'order.firstName_contains': buyer },
            { 'order.lastName_contains': buyer },
            { 'order.title_contains': buyer },
            { 'order.code_contains': buyer },
            { 'order.vatCode_contains': buyer },
            { 'order.address_contains': buyer },
          ];
        }

        if (fromDate) {
          query._where.created_at_gte = fromDate;
        }

        if (toDate) {
          query._where.created_at_lte = toDate;
        }

        query._sort = 'id:asc';

        const invoices = await client.find(query);

        setInvoices(invoices);
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti sąskaitų. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    })();
  }, [type, buyer, fromDate, toDate, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      try {
        setCompanyDetailsList(
          await configurationsClient.find({
            key: 'companyDetails',
            _sort: 'effectiveDate:desc',
          })
        );
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti įmonės informacijos. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    })();
  }, [enqueueSnackbar]);

  return (
    <div>
      {invoices && companyDetailsList ? (
        invoices.map((invoice) => (
          <InvoiceDocument
            key={invoice.id}
            type={type}
            data={invoice}
            companyDetails={
              companyDetailsList.find(
                // NOTE "<=" works because companyDetails are sorted by
                // "created_at" in descending order
                ({ effectiveDate }) => effectiveDate <= invoice.created_at
              ).value
            }
          />
        ))
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

InvoicesList.propTypes = {
  type: PropTypes.oneOf(Object.keys(INVOICE_TYPES)).isRequired,
  buyer: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
};

export default InvoicesList;
