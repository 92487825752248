import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'web-app-components/components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';

export const filters = {
  ALL: 'all',
  NO_GPAIS: 'no-gpais',
  ARCHIVED: 'archived',
};

const filtersArray = Object.values(filters);

const FilterSelectInput = (props) => {
  return (
    <SelectInput {...props}>
      <MenuItem value={filters.ALL}>Visos</MenuItem>
      <MenuItem value={filters.NO_GPAIS}>Be GPAIS</MenuItem>
      <MenuItem value={filters.ARCHIVED}>Archyvas</MenuItem>
    </SelectInput>
  );
};

FilterSelectInput.propTypes = {
  value: PropTypes.oneOf(filtersArray).isRequired,
};

export default FilterSelectInput;
