import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import WarehouseSearch from '../../../../components/WarehouseSearch';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}`}>
        <WarehouseSearch />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
