import React from 'react';
import PropTypes from 'prop-types';
import { Form as FormikForm } from 'formik';
import TextField from 'web-app-components/components/TextField';
import CheckboxField from 'web-app-components/components/CheckboxField';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PriceField from 'goods-common/components/PriceField';

import PackagesField from './components/PackagesField';
import CountrySelectField from './components/CountrySelectField';
import CategoriesSelectField from './components/CategoriesSelectField';
import SuppliersField from './components/SuppliersField';
import DescriptionField from './components/DescriptionField';
import FilesField from './components/FilesField';

const MAX_IMAGE_HEIGHT_PX = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  piguDeliveryPrices: {
    display: 'flex',

    '& > *': {
      flex: 1,
    },

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    // NOTE + 8 because 4px padding top and bottom
    maxHeight: `${MAX_IMAGE_HEIGHT_PX + 8}px`,
    overflowY: 'scroll',

    '& > *': {
      padding: theme.spacing(1),
    },
  },
  image: {
    maxHeight: `${MAX_IMAGE_HEIGHT_PX}px`,
  },
}));

const Form = ({ isSubmitting, values }) => {
  const classes = useStyles();

  return (
    <FormikForm>
      <div className={classes.root}>
        <TextField name="title" label="Pavadinimas" fullWidth />
        <TextField name="color" label="Spalva" fullWidth />
        <TextField name="configuration" label="Derinys" fullWidth />
        <CountrySelectField
          name="countryOfOrigin"
          label="Kilmės šalis"
          fullWidth
        />
        <CategoriesSelectField
          name="categories"
          label="Kategorijos"
          fullWidth
        />
        <TextField name="intrastatCode" label="Intrastato kodas" fullWidth />
        <TextField
          type="number"
          name="packageCount"
          label="Iš kiek dalių (įpakavimų)"
          fullWidth
        />
        <PackagesField name="packages" label="Dalys (įpakavimai)" />
        <SuppliersField name="suppliers" label="Tiekėjai" />
        <div>
          <CheckboxField type="checkbox" name="pigu" label="pigu.lt" />
        </div>
        <PriceField name="price" label="Kaina" displayPigu={values.pigu} />
        <FilesField
          name="images"
          accept="image/*"
          label="Nuotraukos"
          multiple
          disabled={isSubmitting}
        />
        <div>
          <Typography variant="h6">Paveikslėliai iš tiekėjo</Typography>
          <div className={classes.imagesContainer}>
            {values.remoteImages && values.remoteImages.length > 0 ? (
              values.remoteImages.map(({ url }) => (
                <img
                  className={classes.image}
                  key={url}
                  alt="prekės paveikslėlis"
                  src={url}
                />
              ))
            ) : (
              <Typography>Nėra</Typography>
            )}
          </div>
        </div>
        <TextField
          name="summary"
          label="Santrauka"
          multiline
          rows={2}
          rowsMax={4}
          fullWidth
        />
        <DescriptionField
          name="description"
          label="Aprašymas"
          disabled={isSubmitting}
        />
        <TextField
          type="number"
          name="deliveryPrice"
          label="Pristatymo kaina, EUR"
          fullWidth
        />

        {values.pigu && (
          <div className={classes.piguDeliveryPrices}>
            <>
              <TextField
                type="number"
                name="deliveryPriceLt"
                label="Pristatymo kaina (pigu.lt Lietuva), EUR"
              />
              <TextField
                type="number"
                name="deliveryPriceLv"
                label="Pristatymo kaina (pigu.lt Latvija), EUR"
              />
              <TextField
                type="number"
                name="deliveryPriceEe"
                label="Pristatymo kaina (pigu.lt Estija), EUR"
              />
            </>
          </div>
        )}
        <TextField
          type="number"
          name="deliveryTime"
          label="Pristatymo terminas, d.d."
          fullWidth
        />
        <TextField
          type="number"
          name="minOrderAmount"
          label="Minimalus užsakomas kiekis"
          fullWidth
        />
        <TextField
          type="number"
          name="warrantyPeriod"
          label="Garantinis laikotarpis, mėn."
          fullWidth
        />
        <ButtonPrimary type="submit" disabled={isSubmitting}>
          Išsaugoti
        </ButtonPrimary>
      </div>
    </FormikForm>
  );
};

Form.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    pigu: PropTypes.bool,
    remoteImages: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Form;
