import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useDialog from 'web-app-components/hooks/useDialog';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import OrderedDialog from './components/OrderedDialog';

const GoodRow = ({ data, onOrdered }) => {
  const confirmOrderedDialog = useDialog();

  const [loading, setLoading] = useState(false);

  const handleOrderedClick = () => {
    confirmOrderedDialog.setOpen(true);
  };

  const handleConfirmOrdered = async () => {
    setLoading(true);
    await onOrdered(data);
    setLoading(false);
  };

  return (
    <TableRow key={data.id}>
      <TableCell>{data.good.title}</TableCell>
      <TableCell align="right">{data.amount}</TableCell>
      <TableCell align="center">
        {moment(data._order.created_at).format('YYYY-MM-DD')}
      </TableCell>
      <TableCell>
        {loading ? (
          <CircularProgress />
        ) : (
          <ButtonSlim onClick={handleOrderedClick}>Užsakyta</ButtonSlim>
        )}
        <OrderedDialog
          {...confirmOrderedDialog}
          onConfirm={handleConfirmOrdered}
        />
      </TableCell>
    </TableRow>
  );
};

GoodRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    good: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    _order: PropTypes.shape({
      created_at: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onOrdered: PropTypes.func.isRequired,
};

export default GoodRow;
