import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import makeInvoiceNumber from '../../../../../../../../utils/makeInvoiceNumber';

const InvoiceRow = ({ data, onClick }) => {
  const handleClick = () => onClick(data);

  return (
    <TableRow onClick={handleClick}>
      <TableCell>Nr. {makeInvoiceNumber(data.id)}</TableCell>
      <TableCell>{moment(data.created_at).format('YYYY-MM-DD')}</TableCell>
    </TableRow>
  );
};

InvoiceRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,

  onClick: PropTypes.func.isRequired,
};

export default InvoiceRow;
