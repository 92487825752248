import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'web-app-components/components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';

export const filters = {
  REGULAR: 'regular',
  PRE: 'pre',
  CREDIT: 'credit',
};

const filtersArray = Object.values(filters);

const FilterSelectInput = (props) => {
  return (
    <SelectInput {...props}>
      <MenuItem value={filters.REGULAR}>Įprastos</MenuItem>
      <MenuItem value={filters.PRE}>Išankstinės</MenuItem>
      <MenuItem value={filters.CREDIT}>Kreditinės</MenuItem>
    </SelectInput>
  );
};

FilterSelectInput.propTypes = {
  value: PropTypes.oneOf(filtersArray).isRequired,
};

export default FilterSelectInput;
